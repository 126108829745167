.dashboard-page-format {
  justify-content: center;
  display: flex;
  padding: var(--marginPageTop) var(--marginPageSide);
  position: relative;
}
.dashboard-form {
  display: flex;
  flex-direction: column;
  width: 100%; /* Adjust to full width */
  max-width: var(--widthContent_MAX); /* Limit max width */
  min-height: 700px;
  opacity: 0;
  animation: smooth-appear 1s ease forwards;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 30px;
  margin: 0 auto 30px auto; /* Center it and add bottom margin */
}
/* Make sure this matches the width of the page content */
@media (max-width: 900px) {
  .dashboard-page-format {
    padding: 0; /* Set to 0 for smaller screens */
  }
  .dashboard-form {
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    padding: 20px 15px !important;
    
    box-shadow: none;
  }
  .dashboard-header-profile {
    text-align: center;
    margin: min(2vw,20px) auto 0 auto;
  }
  .dashboard-header-stats {
    margin: min(2vw,20px) auto;
  }
}
.dashboard-header-container {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--textSizeSubtitle);
  padding-bottom: 10px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.16);
}
.dashboard-profile-picture {
  --imageHeight: min(22vw,110px);
  height: var(--imageHeight);
  width: var(--imageHeight);
  object-fit: cover;
  overflow-clip-margin: unset;
  border-radius: 900px;
  border: solid var(--colorPrimary) 2px;
}
.dashboard-name {
  color: var(--colorPrimary);
  font-weight: bold;
  font-size: min(5vw,var(--textSizeSubtitle));
  margin-top: 5px;
}
.dashboard-header-stats {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: min(80vw,500px);
  margin-top: 20px;
}
.dashboard-header-stat {
  font-size: min(3vw,var(--textSizeBody));
  text-align: center;
}
.dashboard-header-stat-number {
  font-size: min(4vw, var(--textSizeTitle));
  font-weight: bold;
  color: var(--colorPrimary);
  transition: font-size 0.5s ease, color 0.5s ease;
}

.earnings-updated .dashboard-header-stat-number {
  font-size: calc(min(4vw, var(--textSizeTitle)) * 1.2); /* Increase size by 20% */
  color: green;
}
.dashboard-col {
  width: 200px !important;
}
.dashboard-profile-button {
  background-color: var(--colorPrimary) !important;
  color: var(--colorText3) !important;
  border-radius: 30px !important;
  border: .1rem solid var(--colorPrimary) !important;
  padding: 5px 15px !important;
  font-size: min(4vw,var(--textSizeBody)) !important;
  font-weight: bold;
  margin-top: 10px;
  /* Transition Stuff */
  box-shadow: inset 0 0 0 0 var(--colorPrimary);
  transition: ease-out 0.3s;
}
.dashboard-profile-button:hover{
  box-shadow: inset 200px 0 0 0 var(--colorText1);
}
/* New Request Tab ------------------------------------------------------------------------------------- */
@media (max-width: 900px) {
  .dashboard-entry-container {
    flex-direction: column;
  }
  .dashboard-entry-col-details, .dashboard-entry-col-response {
    margin: 0;
  }
}
.dashboard-tab-title {
  font-size: min(3.5vw,var(--textSizeBody));
}
.dashboard-tab-title li.nav-item::marker {
  content: none;
}
.dashboard-tab-title .nav-link {
  color: black;
}
.dashboard-tab-title .nav-link:hover {
  color: black;
  background-color: #e9ecef;
}
.dashboard-stack {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding:5px;
  border-left: solid 1px #dee2e6;
  border-right: solid 1px #dee2e6;
  border-bottom: solid 1px #dee2e6;
  border-radius: 0 0 10px 10px;
}
.dashboard-entry {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: min(3vw,10px);
  overflow: hidden;
  max-height: 110px; /* Collapsed height */
  transition: max-height 0.6s ease-in-out;
}
.dashboard-entry:hover {
  cursor: pointer;
  background-color: rgba(0,0,0,0.1);
}
.dashboard-entry.expanded {
  max-height: 1000px; /* Max Expanded height */
}
.dashboard-entry-preview {
  display: flex;
  justify-content: space-between;
}
.dashboard-entry-preview-expanded-video, .dashboard-entry-preview-expanded-text{
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #3388FF;
}
.dashboard-entry-preview-expanded-video {
  border-bottom: 2px solid #8a00c2;
}
.dashboard-entry-preview-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: min(3.5vw,var(--textSizeBody));
  text-wrap:nowrap;
  /* margin-bottom: min(0.5vw,10px); */
}
.dashboard-entry-topline {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
.dashboard-entry-price {
  background-color: #04AA6D;
  color: white;
  font-size: min(3.5vw,15px);
  border-radius: min(2vw,10px);
  padding: 0 min(2vw,10px);
  margin-right: 5px;
  display: inline-block;
  white-space: nowrap;
  height: fit-content;
  align-self: baseline;
}

.dashboard-entry-subject {
  font-size: min(4vw,var(--textSizeSubtitle));
  margin-right: 2px;
  font-weight: bold;
}
.dashboard-date {
  font-size: min(3vw,var(--textSizeMini));
  color: gray;
  text-wrap:nowrap;
}
.dashboard-entry-type-video, .dashboard-entry-type-text {
  background-color: #8a00c2;
  padding: 2px min(2vw,10px);
  margin-right: 7px;
  color: white;
  font-size: min(3.5vw,var(--textSizeBody));
  border-radius: 0 min(4vw,20px) 0 0;
}
.dashboard-entry-type-text {
  background-color: #3388FF;
}

.dashboard-entry-container {
  display: flex;
  justify-content: space-between;
}
.dashboard-entry-col-details, .dashboard-entry-col-response {
  /* border: 1px solid #dee2e6; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  background-color: white;
  border-radius: 10px;
  margin: min(2vw,10px);
  padding: min(3vw,10px);
  flex: 1;
}
.dashboard-entry-col-details {
  flex: 1;
  margin-right: 10px;
}
.dashboard-entry-header {
  font-size: min(4vw,var(--textSizeSubtitle));
  font-weight: bold;
}
.dashboard-submission-video {
  width: min(100%,350px);
  aspect-ratio: 16/9;
  margin-top: min(2vw,15px);
  border-radius: 10px;
}
.dashboard-entry-details {
  font-size: min(3.5vw,var(--textSizeBody));
}
.dashboard-entry-generic-text {
  padding-top: 10px;
  font-size: min(3.5vw,var(--textSizeBody));
}
.dashboard-details {
  margin-top: 10px;
}
.dashboard-entry-instructions {
  font-size: min(3vw,var(--textSizeMini));
  line-height: min(3.5vw,var(--textSizeMiniSpacing));
  margin-top: auto;
  display: block;
}
.dashboard-button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  max-width: 400px;
  width: 100%;
  margin-top: 10px;
  gap: 10px;
}
.dashboard-video-button, .dashboard-video-button-change {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}
.dashboard-video-button + label,
.dashboard-video-button-change + label,
.dashboard-button-submit,
.dashboard-button-reject {
  color: var(--colorText3) !important;
  background-color: #04AA6D;
  border-radius: 10px !important;
  border: 0px !important;
  font-size: min(3.5vw,var(--textSizeBody)) !important;
  font-family: var(--fontPrimary) !important;
  padding: 0.4rem 0 !important;
  text-align: center;
  width: min(34vw,170px);
}
.dashboard-video-button-change + label {
  color: var(--colorPrimary) !important;
  background-color: transparent !important;
  border: 2px solid var(--colorPrimary) !important;
}
.dashboard-video-button + label:hover,
.dashboard-video-button-change + label:hover,
.dashboard-button-submit:hover,
.dashboard-button-reject:hover {
  background-image: linear-gradient(rgb(0 0 0/30%) 0 0) !important;
  transition: ease-out 0.3s;
}
.dashboard-video-thumbnail {
  width: 150px;
  border-radius: 10px;
  margin: 20px 0;
  background-color: black;
}
.dashboard-button-reject {
  background-color: red !important;
  display: block;
  margin-top: 10px;
}
.dashboard-button-submit {
  background-color: #04AA6D;
  border: 2px solid #04AA6D !important;
}
.dashboard-rejection-note {
  width: min(90%, 400px);
  margin: 10px 0;
  font-size: min(3.5vw,var(--textSizeBody));
  border-radius: 5px;
}
/* Edit Profile Tab ------------------------------------------------------------------------*/
.dashboard-tab-content {
  padding-top:20px;
  font-size: min(3.5vw,var(--textSizeBody));
}
.dashboard-explination {
  font-size: min(3.5vw,var(--textSizeBody));
  margin-bottom: 5px;
  color: gray;
}
.dashboard-profile-changes {
  color: var(--colorText3) !important;
  background-color: #04AA6D;
  border-radius: 10px !important;
  border: 0px !important;
  font-size: min(3.5vw,var(--textSizeBody)) !important;
  font-family: var(--fontPrimary) !important;
  padding: 0.4rem 1.1rem !important;
  display: block;
  margin-top: 5px;
}
.dashboard-profile-changes:hover {
  background-image: linear-gradient(rgb(0 0 0/30%) 0 0) !important;
}
.dashboard-expertise-remove, .dashboard-expertise-add {
  color: red;
  margin-left: 5px;
  margin-bottom: 5px;
  display: inline-block;
}
.dashboard-expertise-add {
  color: #04AA6D;
}
.dashboard-expertise-remove:hover, .dashboard-expertise-add:hover {
  font-weight: bold;
  cursor: pointer;
}
.dashboard-no-requests-message {
  color: gray;
  font-style: italic;
  padding: 10px;
}

/* Edit Profile Tab ------------------------------------------------------------------------*/
.dashboard-contact-button {
  background-color: var(--colorPrimary) !important;
  color: var(--colorText3) !important;
  border-radius: 10px !important;
  border: .1rem solid var(--colorPrimary) !important;
  padding: 5px 15px !important;
  font-size: min(4vw,var(--textSizeBody)) !important;
  font-family: var(--fontPrimary) !important;
  font-weight: bold;
  margin-top: 10px;
  /* Transition Stuff */
  box-shadow: inset 0 0 0 0 var(--colorPrimary);
  -webkit-transition: ease-out 0.3s;
  -moz-transition: ease-out 0.3s;
  transition: ease-out 0.3s;
}
.dashboard-contact-button:hover{
  background-color: var(--colorText1) !important;
  color: var(--colorText3) !important;
  border: .1rem solid var(--colorText1) !important;
  /* Transition Stuff */
  box-shadow: inset 400px 0 0 0 var(--colorText1);
  transition: ease-out 0.3s;
}
.dashboard-profile-title {
  font-size: min(4vw,var(--textSizeSubtitle));
}
.dashboard-faq-title {
  font-size: min(3.5vw,var(--textSizeSubtitle));
  font-weight: bold;
}
.dashboard-faq-section {
  margin-top: 15px;
}

.dashboard-faq-item {
  margin-bottom: 5px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.dashboard-faq-question {
  font-weight: bold;
}

.dashboard-faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out;
}
.dashboard-faq-answer.expanded {
  max-height: 100%; /* Adjust based on your content */
  padding: 10px 0;
  cursor: default;
}
.dashboard-faq-copytext {
  padding: 10px;
  color: gray;
  font-style: italic;
  border: solid 1px #d1d1d1;
  background-color: #f5f5f5;
}

.profile-picture-container,
.cover-photos-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.profile-picture-preview,
.cover-photo-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
}

.cover-photo-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-cropper-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.image-cropper-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.dashboard-entry-col-response {
  /* Add these properties */
  display: flex;
  flex-direction: column;
  min-height: 300px; /* Adjust this value based on your needs */
}

.conversations-container {
    display: flex;
    gap: 20px;
    height: calc(100vh - 100px); /* Adjust based on your header height */
    min-height: 400px;
    /* Add these to contain all children */
    max-height: calc(100vh - 100px);
    overflow: hidden;
    position: relative; /* Add this to contain absolute children if any */
}

.conversations-empty {
    color: gray;
    font-style: italic;
    padding: 20px;
    text-align: center;
    width: 100%;
}

.conversations-list {
    flex: 1;
    max-width: 300px;
    overflow-y: auto;
    border-right: 1px solid #dee2e6;
}

.conversation-entry {
    display: flex;
    align-items: center;
    padding: 10px 15px; /* Reduced padding to shrink height */
    border-bottom: 1px solid #dee2e6;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.conversation-entry:hover {
    background-color: rgba(51, 136, 255, 0.1);
}

.conversation-entry.selected {
    background-color: rgba(51, 136, 255, 0.2);
}

.conversation-profile-pic {
    width: 40px; /* Reduced from 50px */
    height: 40px; /* Reduced from 50px */
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
}

.conversation-user-info {
    flex: 1;
    min-width: 0; /* Allows proper text truncation */
}

.conversation-name-container {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 5px;
}

.conversation-name {
    font-weight: bold;
    margin-bottom: 0;
}

.conversation-username {
    color: gray;
    font-size: 0.9em;
    white-space: nowrap;
}

.messages-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    /* Add these to ensure proper containment */
    overflow: hidden;
    position: relative;
    max-height: 100%;
}

.messages-header {
    padding: 10px;
    border-bottom: 1px solid #dee2e6;
    display: none;
}

.messages-header span {
    color: var(--colorPrimary);
    cursor: pointer;
}

.messages-list {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    /* Add these to ensure messages stay within bounds */
    position: relative;
    max-height: 100%;
}

.user-info-banner {
  color: var(--colorPrimary);
  background-color: rgba(var(--colorPrimaryRGB), 0.1);
  font-weight: bold;
  padding: min(5vw,10px);
  font-size: min(3.5vw, 17px);
}

.message-group {
    margin-bottom: 20px;
}

.message-date {
    text-align: center;
    color: gray;
    font-size: 0.9em;
    margin: 10px 0;
}

.message-bubble {
    max-width: 90%;
    width: fit-content;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 10px;
    /* Add these to ensure content stays within bubble */
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
}

.message-bubble.own-message {
    margin-left: auto;
    background-color: var(--colorPrimary);
    color: white;
}

.message-bubble.other-message {
    margin-right: auto;
    background-color: #f0f0f0;
    color: black;
}

.message-subject {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: min(3.5vw, 15px);
}

.message-toggle {
    color: inherit;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    font-size: min(3vw, 12px);
    margin-bottom: 3px;
}

.message-content {
    font-size: min(3.2vw, 14px);
    /* Add these to ensure content stays within bounds */
    overflow: hidden;
    max-width: 100%;
}

.conversation-video {
    width: min(50vw, 300px) !important;
    border-radius: 10px;
    margin-top: 10px;
    /* Add this to ensure videos don't overflow */
    max-width: 100%;
}

@media (max-width: 900px) {
    .conversations-list {
        max-width: none;
        border-right: none;
    }

    .mobile-hidden {
        display: none;
    }

    .mobile-only {
        display: block;
    }

    .messages-header {
        display: block;
    }
}

@media (max-width: 550px) {
    .tab-text {
        display: none;
    }
    .dashboard-tab-title svg {
        margin-right: 0 !important;
        font-size: 20px;
    }
    
    /* Update these styles for equal width tabs */
    .dashboard-tab-title {
        width: 100%;
    }
    
    .dashboard-tab-title .nav {
        display: flex;
        width: 100%;
    }
    
    .dashboard-tab-title .nav-item {
        flex: 1;
        width: 100%;
    }
    
    .dashboard-tab-title .nav-link {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 100%;
        padding: 8px 0;
        font-size: 14px;
    }
    .new-request-button {
        width: 100%;
    }
}

.message-bubble.approved-message {
    margin-right: auto;
    background-color: rgba(4, 170, 109, 0.1);
    color: black;
}

.message-subject-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
}

.message-price {
    background-color: #04AA6D;
    color: white;
    font-size: min(3vw, var(--textSizeMini));
    border-radius: 10px;
    padding: 2px 8px;
    white-space: nowrap;
}

.message-price.completed {
    background-color: #808080;  /* Gray color for completed messages */
}

.message-response-link {
    color: #04AA6D;
    /* text-decoration: underline;
    font-weight: bold; */
    cursor: pointer;
    font-size: min(3vw, 12px);
    margin-top: 8px;
}

.conversation-badge {
    background-color: #04AA6D;
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    margin-left: auto;
}

.new-request-button {
    background-color: var(--colorPrimary);
    color: var(--colorText3);
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: min(3.5vw, 20px);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 20px 0 20px auto;
    transition: background-color 0.3s ease;
}

.new-request-button:hover {
    background-color: var(--colorTertiary);
}

.message-response-section {
    margin-top: 10px;
}

.message-response-preview {
    margin-top: 10px;
}

.message-response-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.message-response-buttons label,
.message-response-buttons button {
    flex: 1;
}

.message-actions {
    display: flex;
    gap: 10px;
    margin-top: 5px;
}

.message-reject-button {
    background-color: #ff4444;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 2px 8px;
    font-size: min(3vw, 12px);
    cursor: pointer;
}

.message-submit-button {
    background-color: #04AA6D;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 2px 8px;
    font-size: min(3vw, 12px);
    cursor: pointer;
}

.message-reject-button:hover,
.message-submit-button:hover {
    opacity: 0.9;
}

.message-reject-button:disabled,
.message-submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Update existing styles */
.message-actions {
    display: flex;
    gap: 10px;
    margin-top: 5px;
}

/* New unified styles for all message bubble buttons and labels */
.message-response-link,
.message-reject-button,
.message-submit-button,
.message-response-buttons label {
    font-size: min(3vw, 12px) !important;
    padding: 5px 12px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    min-width: 80px;
    transition: opacity 0.3s ease;
}

.message-response-link {
    color: white;
    background-color: #04AA6D;
    text-decoration: none;
}

.message-reject-button {
    background-color: #ff4444;
    color: white;
    border: none;
}

.message-submit-button {
    background-color: #04AA6D;
    color: white;
    border: none;
}

.message-response-buttons label {
    color: var(--colorPrimary);
    background-color: transparent;
    border: 1px solid var(--colorPrimary);
}

/* Hover states */
.message-response-link:hover,
.message-reject-button:hover,
.message-submit-button:hover,
.message-response-buttons label:hover {
    opacity: 0.9;
}

/* Disabled state */
.message-submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Updated unified button styles */
.message-actions {
    display: flex;
    gap: 10px;
    margin-top: 5px;
}

.message-button-wrapper {
    flex: 1;
}

/* Hide the actual file input */
.dashboard-video-button {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

/* Unified button styles */
.message-button {
    height: 32px;
    font-size: min(3.5vw,var(--textSizeBody)) !important;
    padding: 8px 12px;
    border-radius: 10px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    background-color: #04AA6D;
    color: white;
    border: none;
    text-decoration: none;
  padding: 0.4rem 0 !important;
  text-align: center;
  width: min(34vw,170px);
}

.message-button-reject {
    background-color: #ff4444;
}

/* Hover state */
.message-button:hover {
    opacity: 0.9;
}

/* Disabled state */
.message-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Response preview buttons */
.message-response-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.message-response-buttons label,
.message-response-buttons button {
    flex: 1;
    height: 32px;
}

.message-response-buttons label {
    color: var(--colorPrimary);
    background-color: transparent;
    border: 1px solid var(--colorPrimary);
    display: flex;
    align-items: center;
    justify-content: center;
}

.messages-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: gray;
    font-style: italic;
}

.message-rating-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    margin-left: 0;
}

.message-rating-stars {
    display: flex;
    flex-direction: row;
    gap: 2px;
}

.message-rating-star {
    font-size: min(4vw, 20px);
    cursor: pointer;
    color: grey;
    transition: color 0.3s ease;
}

.message-rating-star.selected {
    color: gold;
}

.message-rating-text {
    font-size: min(3vw, 12px);
    color: grey;
}

/* Update the message toggle container to be flex */
.message-toggle-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 5px;
}

.message-rating-star:hover {
    transform: scale(1.1);
}


@media (max-width: 940px) {
    .about-row {
        gap: 20px   !important;
    }
    .about-row-images{
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
    .about-col-visuals {
        margin: 10px 0 !important;
    }
    .about-image-container {
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        width: 100%;
    }
  }
.about-wrapper {
    padding: calc(var(--marginSectionVertical) * 1) var(--marginPageSide);
}
.about-header {
    font-size: var(--textSizeHeader);
    margin-bottom: min(2vw,30px);
    text-align: center;
    color: var(--colorPrimary);
}
.about-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    gap: 50px;
    max-width: var(--widthContent_MAX);
    margin-bottom: 50px;
}

.about-col-visuals {
    max-width: 450px !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.about-pitch-visual {
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid var(--colorPrimary);
    max-width: 500px;
}

.about-pitch-visual .inner {
    border-radius: 10px;
    padding: 10px 10px 0 10px;
    background: var(--colorBackground);
    color: #fff;
    position: relative;
    z-index: 1;
    width: 100%;
    margin: 5px;
}

.about-pitch-visual::before {
    content: "";
    display: none; /* Remove the rotating background */
}

.about-pitch-title {
    color: var(--colorPrimary);
    font-size: min(5vw,var(--textSizeTitle));
    padding-bottom: min(3vw,var(--paddingSubtitle));

}

.about-pitch-icon-athlete-container {
    display: grid;
    grid-template-columns: repeat(20, auto);
    grid-template-rows: repeat(5, auto);
    gap: 5px;
}


.about-pitch-icon-athlete-gray,
.about-pitch-icon-athlete-color {
    width: min(3vw,15px);
    height: auto;
    color: var(--colorPrimary);
    opacity: 0;
    animation: iconFadeIn 0.5s forwards;
}
.about-pitch-icon-athlete-color {
    color: var(--colorTertiary);
}
.about-col-text {
    max-width: 600px !important;
    font-size: var(--textSizeBody);
    color: var(--colorText1);
    line-height: var(--lineSpacingBody);
}
.about-text-content h3 {
    margin-bottom: 15px;
    color: var(--colorPrimary);
}
.about-contact {
    margin-top: var(--marginSectionVertical);
}

.about-contact-button {
    background-color: var(--colorTertiary) !important;
    color: var(--colorPrimary) !important;
    border-radius: 30px !important;
    border: 0px !important;
    padding: 0.6rem 1.1rem !important;
    font-size: var(--textSizeBody) !important;
    box-shadow: inset 0 0 0 0 var(--colorText3);
    transition: ease-out 0.5s;
}

.about-contact-button:hover {
    background-color: var(--colorPrimary) !important;
    color: var(--colorTertiary) !important;
    box-shadow: inset 400px 0 0 0 var(--colorText3);
    transition: ease-out 0.5s;
}

/* ABOUT US -------------------------------------------------------------------*/
.about-row-images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-right: auto;
    margin-left: auto;
    gap: min(5vw,20px);
    margin-bottom: 30px;
    width: 100%;
    max-width: var(--widthContent_MAX);
}
.about-image-container {
    display: flex;
    flex-direction: row;
    gap: min(10vw,30px);
    align-items: center;
}
.about-image {
    width: min(38vw,220px);
    height: auto;
    aspect-ratio: 4 / 5;
    object-fit: cover;
    overflow-clip-margin: unset;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.about-headshot-caption-container {
    display: flex;
    flex-direction: column;
    justify-content: center !important;

}
.about-headshot-image {
    width: min(25vw,180px);
    height: auto;
    border-radius: 50%;
    object-fit: cover;
    overflow-clip-margin: unset;
    margin-left: auto !important;
    margin-right: auto !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.about-image-caption {
    margin-top: 5px;
    font-size: min(5vw,var(--textSizeTitle));
    color: var(--colorPrimary);
    text-align: center;
    font-weight: bold;
}
.about-image-subcaption {
    font-size: min(3vw,var(--textSizeBody));
    color: var(--colorPrimary);
    text-align: center;
}
.about-col-text-founders {
    font-size: var(--textSizeBody);
    color: var(--colorText1);
    line-height: var(--lineSpacingBody);
}

.about-pitch-source {
    color: var(--colorPrimary);
    font-size: var(--textSizeMini);
    margin-top: 10px;
}

.about-pitch-source a {
    color: var(--colorPrimary);
    text-decoration: none;
}

.about-pitch-source a:hover {
    text-decoration: underline;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes iconFadeIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
    /* from { opacity: 0; }
    to { opacity: 1; } */
}

.about-pitch-icon-person-container .about-pitch-icon-person-color:nth-child(n),
.about-pitch-icon-person-container .about-pitch-icon-person-gray:nth-child(n),
.about-pitch-icon-athlete-container .about-pitch-icon-athlete-color:nth-child(n),
.about-pitch-icon-athlete-container .about-pitch-icon-athlete-gray:nth-child(n) {
    animation-delay: calc(0.02s * var(--n)); /* Use CSS variable for delay */
}
/* Wrappers */
.benefits-wrapper-pitch {
  position: relative;
}

/* Backgrounds */
.benefits-hero-background {
  width: 100%;
  background-color: var(--colorSecondary);
}
.benefits-pitch-background {
  width: 100%;
  background-color: white;
}
.benefits-how-background {
  width: 100%;
  background: 
    radial-gradient(circle at 20% 20%, rgba(var(--colorTertiaryRGB), 0.15), transparent 40%),
    radial-gradient(circle at 80% 80%, rgba(var(--colorPrimaryRGB), 0.15), transparent 40%),
    var(--colorSecondary);
  position: relative;
  overflow: hidden;
  margin-top: calc(var(--marginLip) * -1);
  padding-top: var(--marginLip);
}
.benefits-how-background::before {
  content: '';
  position: absolute;
  inset: 0;
  background: 
    linear-gradient(45deg, transparent 48%, rgba(var(--colorTertiaryRGB), 0.2) 50%, transparent 52%) 0 0 / 20px 20px,
    linear-gradient(-45deg, transparent 48%, rgba(var(--colorPrimaryRGB), 0.2) 50%, transparent 52%) 0 0 / 20px 20px;
  opacity: 0.8;
  animation: patternMove 15s linear infinite;
}

@keyframes patternMove {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 40px;
  }
}

.benefits-features-background {
  width: 100%;
  background-color: rgba(var(--colorTertiaryRGB), 0.1);
}
.benefits-cta-background {
  width: 100%;
  background: linear-gradient(180deg, rgba(var(--colorTertiaryRGB), 0.9) 0%, var(--colorPrimary) 100%);
  position: relative;
  overflow: hidden;
}

/* Common section styles */
.benefits-section-hero,
.benefits-section-pitch,
.benefits-section-how,
.benefits-section-features,
.benefits-section-cta {
  width: 100%;
  background-color: transparent !important;
  max-width: var(--widthContent_MAX);
  margin: 0 auto;
  padding: calc(var(--marginSectionVertical)) var(--marginPageSide);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.benefits-section-cta::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}

/* Section-specific backgrounds */
.benefits-section-hero {
  border-radius: 0 0 60% 60% / 0 0 var(--marginLip) var(--marginLip);
  max-width: 100%;
  overflow: hidden;
  position: relative;
  padding: 0;
}

.benefits-section-pitch {
  background-color: white;
}

.benefits-section-cta {
  background: transparent;
  /* border-radius: 60% 60% 0 0 / var(--marginLip) var(--marginLip) 0 0; */
  padding: calc(var(--marginSectionVertical) * 2.5) var(--marginPageSide) calc(var(--marginSectionVertical) * 2);
  position: relative;
}

.benefits-section-cta::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: inherit;
}

.benefits-section-cta .benefits-section-title,
.benefits-section-cta .benefits-section-subtitle {
  color: white;
  position: relative;
  z-index: 1;
}

/* Common text styles */
.benefits-section-title {
  color: #333;
  font-size: min(var(--textSizeHuge),8vw);
  margin-bottom: 1.5rem;
  text-align: center;
}

.benefits-section-subtitle {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  max-width: 800px;
}

/* Responsive styles */
@media (max-width: 900px) {
  .benefits-section-hero,
  .benefits-section-pitch,
  .benefits-section-how,
  .benefits-section-features,
  .benefits-section-cta {
    padding: calc(var(--marginSectionVertical)) var(--marginPageSide);
  }

  .benefits-section-title {
    margin-bottom: 1rem;
  }

  .benefits-section-subtitle {
    font-size: min(1.2rem, 4vw);
    margin-bottom: 0;
  }
}

/* Hero Section */
.benefits-hero-section {
  width: 100%;
  background-color: var(--colorPrimary);
  border-radius: 0 0 60% 60% / 0 0 var(--marginLip) var(--marginLip);
  padding: 6rem 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.benefits-hero-content {
  max-width: var(--widthContent_MAX);
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.benefits-hero-title {
  font-size: var(--textSizeHuge);
  line-height: var(--lineSpacingHuge);
  background: linear-gradient(to right, var(--colorTertiary), white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  margin-bottom: 1rem;
}

.benefits-hero-subtitle {
  font-size: var(--textSizeHeader);
  color: var(--colorText3);
  padding: var(--paddingHuge) 0;
  max-width: 800px;
  margin: 0 auto;
}

.benefits-hero-image {
  max-width: 500px;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

/* Comparison Table */
.benefits-comparison-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin: 3rem auto;
  background: white;
  border-radius: 12px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  table-layout: fixed;
  border: none;
  position: relative;
  overflow: hidden;
}

.benefits-comparison-table::before {
  display: none;
}

.benefits-comparison-table th,
.benefits-comparison-table td {
  padding: 0.75rem 0.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 0.9rem;
  position: relative;
  border-right: none;
}

.benefits-comparison-table th {
  background: #f8f9fa;
  color: #333;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 1.2;
  font-size: 1.1rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
}

.benefits-comparison-table th span {
  white-space: pre-line;
  display: inline-block;
}

.benefits-comparison-table td:first-child,
.benefits-comparison-table th:first-child {
  text-align: left;
  font-weight: 500;
  color: #2c3e50;
  width: 30%;
  padding-left: 0.75rem;
  padding-right: 0.25rem;
  font-size: 1rem;
  border-right: none;
}

.benefits-comparison-table td:nth-child(2),
.benefits-comparison-table th:nth-child(2) {
  background-color: rgba(var(--colorTertiaryRGB), 0.1);
  animation: pulseBackground 1.5s ease-in-out infinite;
}

@keyframes pulseBackground {
  0% {
    background-color: rgba(var(--colorTertiaryRGB), 0.1);
  }
  50% {
    background-color: rgba(var(--colorTertiaryRGB), 0.2);
  }
  100% {
    background-color: rgba(var(--colorTertiaryRGB), 0.1);
  }
}

.benefits-comparison-table td:not(:first-child),
.benefits-comparison-table th:not(:first-child) {
  text-align: center;
  width: 17.5%;
}

.benefits-comparison-table tr:last-child td {
  border-bottom: none;
}

.benefits-comparison-table tr:hover {
  /* background-color: rgba(var(--colorPrimaryRGB), 0.02); */
  transition: background-color 0.3s ease;
}

@media (max-width: 900px) {
  .benefits-comparison-table {
    margin: 2rem auto max(0.1rem, 0.5vw);
  }

  .benefits-comparison-table th,
  .benefits-comparison-table td {
    font-size: 0.85rem;
    padding: 0.5rem 0;
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .benefits-comparison-table th {
    font-size: 0.75rem;
    padding: 0.5rem 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  }

  .benefits-comparison-table td:first-child,
  .benefits-comparison-table th:first-child {
    width: 40% !important;
    font-size: 0.65rem;
    padding-left: 0.5rem;
    padding-right: 0rem;
    border-right: none;
  }

  .benefits-comparison-table th:nth-child(2),
  .benefits-comparison-table td:nth-child(2) {
    width: 20% !important;
    background-color: rgba(var(--colorTertiaryRGB), 0.1);
  }

  .benefits-comparison-table th:not(:first-child):not(:nth-child(2)),
  .benefits-comparison-table td:not(:first-child):not(:nth-child(2)) {
    display: none;
  }

  .benefits-comparison-table th:nth-child(3),
  .benefits-comparison-table td:nth-child(3) {
    width: 40% !important;
    display: table-cell !important;
  }

  .benefits-comparison-table th.active > *,
  .benefits-comparison-table td.active > * {
    opacity: 1;
    animation: fadeIn 1s ease-in-out;
    animation-fill-mode: both;
  }

  .benefits-comparison-table th.fade-out > *,
  .benefits-comparison-table td.fade-out > * {
    opacity: 0;
    animation: fadeOut 1s ease-in-out;
    animation-fill-mode: both;
  }

  @keyframes fadeIn {
    0% { 
      opacity: 0;
    }
    100% { 
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% { 
      opacity: 1;
    }
    100% { 
      opacity: 0;
    }
  }
}

/* Timeline */
.benefits-timeline {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: min(1px,1vw) 0;
  margin-left: calc(var(--circle-size) / -2);
}

.benefits-timeline::before {
  content: '';
  position: absolute;
  top: 80px;
  bottom: 90px;
  left: var(--timeline-left);
  width: 4px;
  background-color: #999;
}

.benefits-timeline-entry {
  position: relative;
  margin: 20px 0;
  display: flex;
}

.benefits-timeline-circle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: var(--timeline-left);
  width: var(--circle-size);
  height: var(--circle-size);
  margin-left: calc(-1 * var(--circle-size) / 2);
  background-color: var(--colorPrimary);
  border-radius: 50%;
  color: var(--colorText3);
  text-align: center;
  font-weight: bold;
  font-size: min(var(--textSizeTitle),5vw);
  display: flex;
  align-items: center;
  justify-content: center;
}

.benefits-timeline-content {
  width: calc(100% - var(--timeline-left) - min(50px,10vw));
  min-width: 0;
  border-bottom: 5px solid var(--colorPrimary);
  padding: var(--content-padding);
  border-radius: 10px;
  position: relative;
  left: calc(var(--timeline-left) + min(50px,10vw));
  opacity: 0;
  animation: revealFromLeft 1s forwards;
  background-color: white;
}

.benefits-timeline-content::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(var(--content-padding) * -1);
  border-width: 10px 20px 10px 0;
  border-style: solid;
  border-color: transparent white transparent transparent;
}

.benefits-timeline-icon {
  margin-right: min(3vw,20px);
}

.benefits-timeline-title {
  color: var(--colorPrimary);
  font-weight: bold;
  font-size: min(var(--textSizeTitle),5vw);
  line-height: min(var(--textSizeTitleSpacing),7vw);
  display: flex;
  align-items: center;
}

.benefits-timeline-text {
  color: #A5A5A5;
  font-size: min(var(--textSizeBody),4vw);
}

@media (max-width: 1220px) {
  .benefits-timeline::before {
    top: 90px !important;
    bottom: 90px !important;
  }
}

@media (max-width: 940px) {
  .benefits-timeline-content {
    width: calc(100% - 40px) !important;
    min-width: 0;
  }
}

/* @media (max-width: 600px) {
  .benefits-timeline {
    margin-left: 0 !important;
  }
  .benefits-timeline::before {
    top: 90px !important;
    bottom: 90px !important;
  }
} */

/* Features Section */
.benefits-features {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 320px));
  gap: 2rem;
  margin: 1.5rem 0 3rem;
  justify-content: center;
}

.benefits-feature {
  background: var(--colorPrimary);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
  text-align: center;
  width: 100%;
}

.benefits-feature:hover {
  transform: translateY(-5px);
}

.benefits-feature-icon {
  font-size: 3rem;
  color: white;
  margin-bottom: 1.5rem;
}

.benefits-feature h3 {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: bold;
}

.benefits-feature p {
  color: white;
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 0;
  opacity: 0.9;
}

/* CTA Section */
.benefits-cta-button {
  background: white;
  color: var(--colorPrimary);
  padding: 0.8rem 2.5rem;
  border-radius: 30px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.benefits-cta-button:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

@media (max-width: 700px) {
  .benefits-cta-button {
    width: 100%;
    text-align: center;
    padding: 0.8rem 1rem;
    margin-top: 1rem;
  }
}

/* Responsive Styles */
@media (max-width: 900px) {
  .benefits-wrapper {
    padding: 0;
  }

  .benefits-section {
    padding: calc(var(--marginSectionVertical) * 2) 0;
  }

  .benefits-section-content {
    padding: 0 1rem;
  }

  .benefits-hero-section {
    padding: 4rem 0;
    border-radius: 0 0 30% 30% / 0 0 var(--marginLip) var(--marginLip);
  }

  .benefits-hero-content {
    padding: 0 1rem;
  }

  .benefits-hero-title {
    font-size: min(var(--textSizeHuge), 10vw);
    line-height: 1.2;
  }

  .benefits-hero-subtitle {
    font-size: min(var(--textSizeBody), 4.5vw);
    padding: calc(var(--paddingHuge) / 2) 0;
  }

  .benefits-hero-image {
    max-width: 100%;
    margin-top: 1.5rem;
  }


  .benefits-features {
    grid-template-columns: 1fr;
  }

  .benefits-section-cta {
    border-radius: 30% 30% 0 0 / calc(var(--marginLip) * 0.7) calc(var(--marginLip) * 0.7) 0 0;
    padding: calc(var(--marginSectionVertical) * 2) var(--marginPageSide) calc(var(--marginSectionVertical) * 1.5);
  }
} 

.section {
  margin: 0;
  padding: 6rem 0;
  width: 100%;
}

.section > * {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.section:nth-child(odd) {
  background: white;
}

.section:nth-child(even) {
  background: #f8f9fa;
}

.hero-section {
  background: white;
  padding: 4rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.hero-section > * {
  max-width: 1200px;
  padding: 0 2rem;
  width: 100%;
}

.hero-content {
  flex: 1;
}

.hero-image {
  flex: 1;
  max-width: 500px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
}

.section-header {
  text-align: center;
  margin-bottom: 3rem;
}

.section-subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-top: 1rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.feature-card {
  background: #fff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 2.5rem;
  color: var(--colPrimary);
  margin-bottom: 1rem;
}

.steps-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  position: relative;
  padding: 1rem 0;
}

/* Line connecting all steps */
.steps-container::before {
  content: '';
  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
  height: 2px;
  background: #e0e0e0;
  z-index: 1;
}

.step-item {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-number {
  width: 60px;
  height: 60px;
  background: var(--colPrimary);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  position: relative;
  border: 4px solid white;
  box-shadow: 0 0 0 2px var(--colPrimary);
  transition: transform 0.3s ease;
  padding: 1.5rem;
}

.step-content {
  text-align: center;
  padding: 0 1rem;
}

.step-content h3 {
  color: #2c3e50;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.step-content p {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 0;
}

.step-item:hover .step-number {
  transform: scale(1.1);
  background: var(--colPrimary);
}

.cta-section {
  text-align: center;
  background: var(--colPrimary);
  color: white;
  padding: 4rem 0;
  margin: 0;
  width: 100%;
}

.cta-section > * {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.cta-button {
  background: white;
  color: var(--colPrimary);
  padding: 1rem 2rem;
  border-radius: 30px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  margin-top: 2rem;
  transition: transform 0.3s ease;
}

.cta-button:hover {
  transform: scale(1.05);
}

.comparison-table {
  width: min(100%, 1000px);
  border-collapse: separate;
  border-spacing: 0;
  margin: 2rem auto;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
}

.comparison-table th,
.comparison-table td {
  padding: 1.5rem;
  text-align: center;
  border-bottom: 1px solid #eef1f5;
}

.comparison-table th {
  background: #f8f9fa;
  color: #333;
  font-weight: 600;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.comparison-table th:first-child {
  text-align: left;
}

.comparison-table td:first-child {
  text-align: left;
  font-weight: 500;
  color: #2c3e50;
}

.comparison-table tr:last-child td {
  border-bottom: none;
}

.comparison-table tr:hover {
  background: #f8f9fa;
  transition: background 0.2s ease;
}

.comparison-table td {
  font-size: 1.2rem;
}

/* Make checkmarks and crosses more stylish */
.comparison-table td:not(:first-child) {
  color: #2ecc71;
}

.comparison-table td:not(:first-child):contains('❌') {
  color: #e74c3c;
}

@media (max-width: 768px) {
  .section {
    padding: 4rem 0;
  }

  .section > *,
  .hero-section > *,
  .cta-section > * {
    padding: 0 1rem;
  }

  .hero-section {
    padding: 3rem 0;
  }
  
  .hero-image {
    width: 100%;
  }
  
  .section-title {
    font-size: 2rem;
  }
  
  .comparison-table {
    font-size: 0.9rem;
  }
  
  .comparison-table th,
  .comparison-table td {
    padding: 1rem 0.5rem;
  }
  
  .comparison-table th {
    font-size: 0.9rem;
  }
  
  .comparison-table td {
    font-size: 1rem;
  }
  
  .steps-container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .steps-container::before {
    top: 0;
    bottom: 0;
    left: 30px;
    width: 2px;
    height: 100%;
  }

  .step-item {
    text-align: left;
    display: grid;
    grid-template-columns: 60px 1fr;
    gap: 1.5rem;
    align-items: start;
  }

  .step-number {
    margin: 0;
    width: 50px;
    height: 50px;
    padding: 1.2rem;
    transform: translateX(5px);
  }

  .step-content {
    text-align: left;
    padding: 0;
  }
}

/* Update the last features section to have black background */
.benefits-section:last-of-type:not(.benefits-cta) {
  background: black;
}

/* Update text colors in the black section */
.benefits-section:last-of-type:not(.benefits-cta) .benefits-section-title,
.benefits-section:last-of-type:not(.benefits-cta) .benefits-section-subtitle {
  color: white;
}

@media (max-width: 1200px) {
  .benefits-features {
    grid-template-columns: repeat(2, minmax(0, 320px));
  }
}

@media (max-width: 700px) {
  .benefits-features {
    grid-template-columns: minmax(0, 320px);
    justify-items: center;
  }
  
  .benefits-feature {
    width: 100%;
  }
}

.benefits-hero-row {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: var(--widthContent_MAX);
  text-align: center;
  padding: min(4rem, 10vw) 0;
  position: relative;
  z-index: 2;
}

.benefits-hero-col-text {
  max-width: 550px !important;
  align-items: center;
  display: flex;
}

.benefits-hero-container {
  animation: fadeIn 0.75s ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  max-width: 900px;
  gap: min(2rem, 6vw);
}

.benefits-hero-huge {
  font-size: var(--textSizeHuge);
  line-height: var(--lineSpacingHuge);
  background: linear-gradient(to right, var(--colorTertiary), white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: bold;
}

.benefits-hero-text {
  font-size: min(4.5vw,var(--textSizeSubtitle));
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
  color: var(--colorText3);
  text-align: center;
  max-width: 800px;
}

.benefits-hero-button-container {
  display: flex;
  gap: 10px;
}

.benefits-hero-button-join {
  background: linear-gradient(120deg, white, var(--colorTertiary)) !important;
  color: var(--colorPrimary) !important;
  border-radius: 30px !important;
  border: 0px !important;
  padding: 0.8rem 1.6rem !important;
  font-size: calc(var(--textSizeBody) * 1.1) !important;
  margin: 0.5rem 0px;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.benefits-hero-button-join:hover {
  color: var(--colorPrimary) !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background: white !important;
}

@media (max-width: 700px) {
  .benefits-hero-button-container {
    width: 100%;
  }
  
  .benefits-hero-button-join {
    width: 100%;
    padding: 0.7rem 1.4rem !important;
    font-size: var(--textSizeBody) !important;
  }
}

/* How It Works section modifications */
.benefits-section-how {
  background-color: transparent !important;
  padding: calc(var(--marginSectionVertical) * 0.75) var(--marginPageSide) calc(var(--marginSectionVertical) / 3);
}

.benefits-how-content {
  display: flex;
  gap: 4rem;
  max-width: var(--widthContent_MAX);
  margin: 2.5rem auto 0;
  width: 100%;
}

.benefits-how-image-col {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem;
}

.benefits-how-image-col::before {
  display: none;
}

.benefits-hero-iphone {
  max-height: 600px;
  width: auto;
  margin: 0 auto;
  border-radius: 45px;
  box-shadow: 
    0 0 0 1px rgba(255, 255, 255, 0.1),
    0 4px 20px rgba(0, 0, 0, 0.1),
    0 12px 40px -10px rgba(var(--colorPrimaryRGB), 0.2);
  object-fit: contain;
  height: auto;
  position: relative;
  z-index: 1;
}

.benefits-how-timeline-col {
  flex: 1.5;
  max-width: 100%;
  overflow: visible;
  padding-left: calc(var(--circle-size) / 2);
}

@media (max-width: 940px) {
  .benefits-hero-row {
    padding: min(4rem, 8vw) 0;
  }

  .benefits-hero-container {
    gap: min(3rem, 5vw);
  }

  .benefits-hero-huge {
    max-width: 100%;
  }

  .benefits-hero-text {
    max-width: 100%;
    text-align: start;
  }

  .benefits-hero-button-join {
    padding: 0.7rem 1.4rem !important;
    font-size: var(--textSizeBody) !important;
  }

  .benefits-how-timeline-col {
    padding-left: calc(var(--circle-size) / 2);
  }

  .benefits-how-content {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    gap: 2rem;
  }

  .benefits-how-image-col {
    width: 100%;
    max-width: 400px;
    padding: 1rem;
  }

  .benefits-hero-iphone {
    width: auto;
    max-width: 100%;
    height: auto;
  }

  .benefits-timeline-content {
    width: calc(100% - 80px);
    min-width: 0;
  }
}

.request-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.request-form-container {
    position: relative;
    background: white;
    padding: 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
}
@media (max-width: 900px) {
    .request-form-container {
        width: 100%;
        max-height: 100%;
        padding: 15px;
        margin: 10px ;
    }
}

.close-button {
    position: absolute;
    top: 0px;
    right: 10px;
    background: transparent !important;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #666;
    transition: color 0.3s ease;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    padding: 0;
}

.close-button:hover {
    color: #ff4444;
}

.close-button:focus,
.close-button:active,
.close-button:focus-visible {
    outline: none;
    background: transparent !important;
    -webkit-tap-highlight-color: transparent;
    box-shadow: none;
}

.progress-indicator {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}

.progress-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd;
    transition: background-color 0.3s ease;
}

.progress-dot.active {
    background-color: var(--colorPrimary);
}

.form-content {
    flex: 1;
    overflow-y: auto;
    padding-right: 10px;
    margin-bottom: 20px;
}

.navigation-buttons {
    position: sticky;
    bottom: 0;
    background: white;
    padding-top: 10px;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
}


.nav-button {
    background: var(--colorPrimary);
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-button.back {
    margin-right: auto;
}

.nav-button.next {
    margin-left: auto;
}

.nav-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Form inputs styling */
.form-group {
    margin-bottom: 15px;
    flex: 1;
}

.form-title {
    color: var(--colorPrimary);
    margin-bottom: 10px;
}

.form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: min(18px, 4vw);
}

.form-input {
    width: 100%;
    padding: min(10px, 2vw) min(15px, 3vw);
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: min(14px, 3.5vw);
}

.form-input::placeholder {
    color: #666;
    font-style: italic;
    font-size: min(14px, 3.5vw);
}

/* Ensure these styles come after any other .form-input styles in your CSS file */
.request-form-container .form-input {
    padding: min(10px, 2vw) min(15px, 3vw);
    font-size: min(14px, 3.5vw);
}

.request-form-container textarea.form-input {
    padding: min(15px, 3vw);
}

/* Thank you message styling */
.thank-you-message {
    text-align: center;
    padding: 40px 20px;
}


.form-row {
    display: flex;
    gap: 15px;
}

.form-group {
    margin-bottom: 15px;
    flex: 1;
}

textarea.form-input {
    min-height: 100px;
    resize: vertical;
}

.character-count {
    text-align: right;
    font-size: 12px;
    color: #666;
    margin-top: 5px;
}

.file-input {
    width: 100%;
    padding: 10px;
    border: 2px dashed #ddd;
    border-radius: 4px;
    cursor: pointer;
}

.file-input.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.file-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 5px;
    font-size: 14px;
    color: #666;
}

.payment-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.payment-button {
    padding: 15px;
    border: 2px solid var(--colorPrimary);
    color: var(--colorPrimary);
    border-radius: 4px;
    background: none;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
}

.payment-button.selected {
    background: var(--colorPrimary);
    color: white;
}

.custom-amount {
    display: flex;
    align-items: center;
    gap: 10px;
}

.custom-amount input {
    width: 100px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.summary {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 4px;
    margin: 20px 0;
}

.summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.mock-stripe {
    background: #f8f8f8;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center;
    margin: 20px 0;
}

.captcha-container {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.submit-button {
    width: 100%;
    padding: 15px;
    background: var(--colorPrimary);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submit-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.placeholder-fade::placeholder {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
}

.placeholder-fade.transitioning::placeholder {
    opacity: 0;
}

.question-subtitle {
    color: #666;
    font-size: 14px;
    margin-bottom: 15px;
}

.question-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.written-option, .video-option {
    transition: all 0.3s ease;
}

.form-input.active {
    border-color: var(--colorTertiary);
    box-shadow: 0 0 0 1px var(--colorTertiary);
}

.file-upload-area {
    background: white;
    border: 2px solid var(--colorTertiary);
    border-radius: 12px;
    padding: min(20px, 4vw);
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    min-height: min(100px, 20vw);
}

.file-upload-area:has(.video-preview) {
    padding: min(10px, 1vw);
    min-height: auto;
}

.file-upload-area:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.file-upload-area.active {
    border-color: var(--colorPrimary);
    background: var(--colorPrimary);
    color: white;
}

.file-upload-area.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.upload-prompt {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.upload-subtitle {
    font-size: 12px;
    color: #666;
}

.file-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 5px;
    font-size: 14px;
    color: #666;
}

.remove-file {
    background: none;
    border: none;
    color: #ff4444;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 4px;
}

.remove-file:hover {
    background: #ffeeee;
}

/* Disable styles */
.form-input:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.file-upload-area.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

/* Add these styles for video handling */
.video-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: min(8px, 1.5vw);
    margin: 0;
}

.video-thumbnail {
    max-width: 100%;
    max-height: 150px;
    width: auto;
    border-radius: 4px;
    background-color: black;
}

.change-video {
    background: rgba(255, 255, 255, 0.9);
    border: none;
    color: var(--colorPrimary);
    cursor: pointer;
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.change-video:hover {
    background: white;
    transform: translateY(-1px);
}


/* Add these styles to your existing CSS */
.form-subtitle {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
}

.form-error {
    color: #ff4444;
    font-size: 12px;
    margin-top: 4px;
}

.form-input.error {
    border-color: #ff4444;
}

.form-input.error:focus {
    border-color: #ff4444;
    box-shadow: 0 0 0 1px #ff4444;
}

select.form-input {
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 1em;
    padding-right: 40px;
}

/* Add these styles for the payment step */
.custom-amount-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.custom-amount {
    display: flex;
    align-items: center;
    gap: 10px;
}

.custom-amount-input {
    width: 100px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    -moz-appearance: textfield; /* Firefox */
}

/* Remove arrows for Chrome, Safari, Edge, Opera */
.custom-amount-input::-webkit-outer-spin-button,
.custom-amount-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.amount-note {
    font-size: 12px;
    color: #666;
    margin-left: 10px;
}

/* Add styles for the payment form */
.payment-form-container {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
}

.payment-form {
    min-height: 200px;
}

/* Style the scrollbar */
.form-content::-webkit-scrollbar {
    width: 8px;
}

.form-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.form-content::-webkit-scrollbar-thumb {
    background: var(--colorPrimary);
    border-radius: 4px;
}

.form-content::-webkit-scrollbar-thumb:hover {
    background: var(--colorPrimaryDark);
}


.topic-bubbles {
    display: flex;
    flex-wrap: wrap;
    gap: min(10px, 2vw);
    margin-top: 12px;
}

.topic-bubble {
    background: white;
    border: 1px solid var(--colorPrimary);
    color: var(--colorPrimary);
    padding: min(8px, 1.5vw) min(16px, 3vw);
    border-radius: 20px;
    font-size: min(12px, 3vw);
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: min(8px, 1.5vw);
}

.topic-bubble-icon {
    font-size: min(14px, 3.5vw);
    display: flex;
    align-items: center;
}

.topic-bubble:hover {
    background: var(--colorPrimary);
    color: white;
    transform: translateY(-2px);
}

/* Update existing styles */
.question-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.written-option textarea.form-input {
    border: 2px solid var(--colorTertiary);
    border-radius: 12px;
    padding: 15px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.written-option textarea.form-input:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.written-option textarea.form-input.active {
    border-color: var(--colorPrimary);
}

.option-divider {
    text-align: center;
    color: #888;
    font-weight: 500;
    position: relative;
}

.option-divider::before,
.option-divider::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 45%;
    height: 1px;
    background: #ddd;
}

.option-divider::before {
    left: 0;
}

.option-divider::after {
    right: 0;
}

/* Update form labels to be more modern */

.question-subtitle {
    color: #666;
    font-size: min(15px, 3.5vw);
    line-height: 1.4;
}




/* Sitewide variables ----------------------------------------------------------------*/
:root {
    /* Font Style */
    --fontPrimary: "DM Sans";
    /* Text - Mini */
    --textSizeMini: 0.8rem;
    --textSizeMiniSpacing: 1.1rem;
    /* Text - Body */
    --textSizeBody: 1.1rem;
    --textSizeBodySpacing: .6rem;
    --paddingBody: 0.6rem;
    /* Text - Subtitle */
    --textSizeSubtitle: 1.3rem;
    --paddingSubtitle: 0.8rem;
    /* Text - Title */
    --textSizeTitle: 25px;
    --textSizeTitleSpacing: 2.2rem;
    --paddingTitle: 20px;
    /* Text - Header */
    --textSizeHeader: min(7vw,2.1rem);
    --textSizeHeaderSpacing: 2.4rem;
    --paddingHeader: min(5vw,40px);
    /* Text - Huge */
    --textSizeHuge: min(10vw,3.5rem);
    --lineSpacingHuge: min(12vw,4rem);
    --paddingHuge: 1rem;
    /* Colors */
    --colorBackground: #F5F5F5;
    --colorText1: black;
    --colorText2: #BBBBBB;
    --colorText3: white;
    --colorVideo: #8a00c2;
    --colorMessage: #3388FF;
    --colorHamburger: transparent;
    --colorPrimary: #27476E;
    --colorTertiary: #ECA400;
    --colorSecondary: #eaeaea;
    --colorPrimaryRGB: 39, 71, 110;
    --colorTertiaryRGB: 236, 164, 0;

    /* --colorPrimary: #A20021;
    --colorTertiary: #FCDC4D;
    --colorSecondary: #eaeaea;
    --colorPrimaryRGB: 162, 0, 33;
    --colorTertiaryRGB: 252, 220, 77; */
  
    /* --colorPrimary: #001D4A;
    --colorTertiary: #ECA400;
    --colorSecondary: #eaeaea;
    --colorPrimaryRGB: 0, 29, 74;
    --colorTertiaryRGB: 236, 164, 0; */
  
    /* --colorPrimary: #006992;
    --colorTertiary: #ECA400;
    --colorSecondary: #eaeaea;
    --colorPrimaryRGB: 0, 105, 146;
    --colorTertiaryRGB: 236, 164, 0; */
  
    /* Margins and Structure */
    --widthContent_MAX: 1100px;
    --marginPageSide: 20px;
    --marginSectionVertical: min(5vw,40px);
    --marginSectionHorizontal: min(5vw,80px);
    --marginPageTop: 20px;
    --marginLip: min(7vw,50px);
    /* Timeline */
    --timeline-left: 1rem;
    --circle-size: min(50px,10vw);
    --content-padding: min(20px,4vw);
    /* Athlete Profile Page */
    --athlete-pageContentWidth: 900px;
    --athlete-ImageHeight: min(30vw,150px);
    --athlete-ImageMargin: min(5vw,1.5rem);
    --athlete-SectionBorders: 10px;
    --athlete-SectionGap: 10px;
    --athlete-SectionPadding: 20px;
    --athlete-SectionShadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }


  /* Sitewide Preferences --------------------------------------------------------------------------------------------------- */
  .App-wrapper {
    background-color: var(--colorBackground) !important;
    margin: auto;
    opacity:0;
    animation: smooth-appear 1s ease forwards;
    min-height: 100vh;
  }
  @keyframes smooth-appear {
    to{
      opacity:1;
    }
  }
  * {
    font-family: var(--fontPrimary);
  }
  a:link, a:visited, a:hover, a:active {
    text-decoration: none !important;
    color: inherit;
  }
  li::marker {
    content: '- ';
    font-size: 1.2em;
  }
  .text-color-primary {
    color: var(--colorPrimary);
    display: inline
  }
  .text-color-tertiary {
    color: var(--colorTertiary);
    display: inline
  }
  .text-color-gray {
    color: gray;
    display: inline;
  }
  .text-color-white {
    color: white;
    display: inline
  }
  .text-bold-underline {
    font-weight: bold;
    text-decoration: underline;
    display: inline;
  }
  .text-infield-blue {
    display: inline-block;
    background-color: var(--colorPrimary);
    color: white;
    font-style: italic;
    font-weight: bold;
    transform: skew(-10deg);
    padding: 0px 8px;
  }
  .text-infield-gold {
    display: inline-block;
    background-color: var(--colorTertiary);
    color: white;
    font-style: italic;
    font-weight: bold;
    transform: skew(-10deg);
    padding: 0px 8px;
  }

  
  /* Debug tools*/
  .debug {
    outline: 1px solid red;
  }
  .debug1 {
    outline: 1px solid greenyellow;
  }
.passwordreset-title {
    margin: 10px 0;
    font-size: var(--textSizeTitle);
    text-align: center;
}
.password-input-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 1rem; /* Add some space between input fields */
}
.passwordreset-input {
    width: 300px;
    border-radius: 5px;
    border: 1px solid black;
}
.contact-info {
    width: 100%;
    padding-right: 2rem; /* Adjust padding to make space for the icon */
}
.password-toggle-icon {
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
}
.passwordreset-success {
    margin-top: 10px;
    color: green;
}
.passwordreset-error {
    margin-top: 10px;
    color: red;
}
.profile-wrapper {
  padding: var(--marginPageTop) min(0vw,var(--marginPageSide));
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
/* Make sure this matches the width of the page content */
@media (max-width: 900px) {
  .profile-wrapper {
      padding-left: 0; /* Set to 0 for smaller screens */
      padding-right: 0;
      padding-top: 0;
  }
  .profile-cover-section, .profile-cover, .profile-name-cover, .profile-section, .profile-cover-image {
    border-radius: 0px !important;
  }
  .profile-cover {
    aspect-ratio: 16/9 !important;
  }
  .profile-name-container {
    margin-right: var(--athlete-ImageMargin);
  }
  /* .profile-button {
    width: 100% !important;
  } */
  .profile-rating {
    margin-right: 0 !important;
  }
}
.profile-cover-section {
margin-bottom: var(--athlete-SectionGap);
box-shadow: var(--athlete-SectionShadow);
border-radius: var(--athlete-SectionBorders);
}
.profile-cover {
/* background-color: rgba(var(--colorPrimaryRGB),0.7); */
background-color: black;
border-top-right-radius: var(--athlete-SectionBorders);
border-top-left-radius: var(--athlete-SectionBorders);
aspect-ratio: 3;
position: relative;
}
.profile-cover-image {
width: 100%;
height: 100%;
object-fit: cover;
position: absolute;
overflow: hidden;
top: 0;
left: 0;
opacity: 0;
transition: opacity 0.5s ease-in-out;
/* border-top-right-radius: var(--athlete-SectionBorders);
border-top-left-radius: var(--athlete-SectionBorders); */
}
.profile-cover-image.active {
opacity: 1;
}
.profile-picture {
--imageHeight: min(30vw,var(--athlete-ImageHeight));
height: var(--imageHeight);
aspect-ratio: 1;
object-fit: cover;
position: absolute;
overflow-clip-margin: unset;
bottom: calc(var(--imageHeight) * -0.5);
margin-left: var(--athlete-ImageMargin);
border-radius: 900px;
border: solid var(--colorTertiary);
border-width: 4px;
z-index: 10 !important;
}
.profile-name-cover {
background-color: white;
border-top: 4px solid var(--colorTertiary);
border-bottom-right-radius: var(--athlete-SectionBorders);
border-bottom-left-radius: var(--athlete-SectionBorders);
}
.profile-name-container {
padding-top: calc(var(--athlete-ImageHeight) /2);
padding-bottom: 10px;
margin-left: var(--athlete-ImageMargin);
position: relative;
}
.profile-name {
font-size: min(6vw,var(--textSizeTitle));
}
.profile-sport {
font-size: min(4.5vw,21px);
}
.profile-achievement-container {
margin-top: min(1vw,5px);
}
.profile-achievement-icon{
height: min(5vw,25px);
width: auto;
margin-right: min(1vw,5px);
color: var(--colorTertiary)
}
.profile-achievement {
margin-bottom: min(5vw,3px);
display: flex;
}
.profile-achievement{
font-size: min(3.5vw,var(--textSizeBody));
font-weight: bold;
}
.profile-socialmedia-container {
margin-top: 10px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
}
.profile-socialmedia {
margin-right: 15px;
font-size: min(3.5vw,var(--textSizeBody));
padding-bottom: 5px;
}
.profile-socialmedia:hover {
color: var(--colorPrimary);
}
.profile-socialmedia-icon{
height: 20px;
width: auto;
}
.profile-button-active {
    display: flex;
    align-items: center;
    justify-content: center !important;
    height: 40px !important;
    padding: 0 1rem !important;
    color: white !important;
    border: none !important;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--colorPrimary) !important;
    /* animation: buttonPulse 1s ease-in-out infinite;
    transition: all 0.3s ease; */
}

.profile-button-paused {
    display: flex;
    align-items: center;
    justify-content: center !important;
    height: 40px !important;
    padding: 0 1rem !important;
    color: #3388FF !important;
    border: none !important;
    border-radius: 10px;
    cursor: not-allowed;
    background-color: #E6F0FF !important;
    box-shadow: none !important;
}

.profile-button-active:hover {
    animation: none;
    background-color: var(--colorTertiary) !important;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(var(--colorTertiaryRGB), 0.2);
}

.profile-button-paused:hover {
    background-color: #E6F0FF !important;
    transform: none;
    box-shadow: none;
}

@keyframes buttonPulse {
    0% {
        background-color: var(--colorTertiary);
    }
    50% {
        background-color: rgba(var(--colorTertiaryRGB), 0.7);
        transform: scale(1.05);
    }
    100% {
        background-color: var(--colorTertiary);
    }
}

.profile-button-icon {
    margin-right: min(2vw,8px);
    font-size: 25px;
    transition: ease-out 0.5s;
}

.profile-button-text {
    font-size: min(4vw,15px);
}
.profile-section{
background-color: white;
border-radius: var(--athlete-SectionBorders);
margin-bottom: var(--athlete-SectionGap);
box-shadow: var(--athlete-SectionShadow);
padding: var(--athlete-SectionPadding);
}
.profile-header {
  font-size: min(6vw,25px);
  font-weight: bold;
}
.profile-bio {
  font-size: min(4vw,var(--textSizeBody));
}
.profile-expertise {
  font-size: min(4vw,var(--textSizeBody));
  padding: calc(var(--textSizeBodySpacing)/2) 0;
}
.profile-expertise-icon{
  height: min(5vw,25px);
  width: auto;
  margin-right: min(5vw,5px);
  color: var(--colorTertiary);
}
/* .profile-rating {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
} */
 .profile-interact {
  display: flex;
  margin-top: min(2vw,10px);
 }
 .profile-rating {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}

.profile-rating-top {
  display: flex;
  align-items: center;
}

.profile-star {
  position: relative;
  display: inline-block;
  width: min(4vw, 20px);
  height: min(4vw, 20px);
  margin-right: 2px; /* Add some space between stars */
}
.profile-star-color {
  color: var(--colorTertiary);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.profile-star-gray {
  color: #F0F0F0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.rating-score {
  font-size: min(3vw, 15px);
  font-weight: bold;
  margin-left: 5px; /* Add some space between stars and score */
}

.rating-text {
  font-size: min(4vw, 0.8rem);
  color: #666;
  font-weight: bold;
  margin-top: 2px;
}

.pause-toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.pause-toggle {
  width: 50px;
  height: 25px;
  background-color: #4cd964; /* Green color for accepting requests */
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pause-toggle.active {
  background-color: #ccc; /* Gray color for paused state */
}

.toggle-circle {
  width: 23px;
  height: 23px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 26px;
  transition: left 0.3s;
}

.toggle-circle.active {
  left: 1px; /* Move to the right when active */
}

.pause-toggle-text {
  margin-left: 10px;
  font-size: 16px;
}

.profile-location {
  font-size: min(4.5vw,21px);
    font-style: italic;
    display: flex;
    align-items: center;
}

.profile-location svg {
    margin-left: 20px !important;
    width: 1em !important;
    height: 1em !important;
}

.profile-cover-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.profile-cover-wrapper.active {
    opacity: 1;
}

.profile-cover-background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(20px) brightness(0.7);
    transform: scale(1.1); /* Slightly scale up to prevent blur edges showing */
}

.profile-cover-image {
    position: absolute;
    height: 100%;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    object-fit: contain;
}
.confirmation-content {
  text-align: center;
  padding: 40px 20px;
}

.confirmation-content h2 {
  color: var(--colorPrimary);
  margin-bottom: 20px;
  font-size: 24px;
}

.confirmation-content p {
  color: #666;
  line-height: 1.6;
  font-size: 16px;
  max-width: 400px;
  margin: 0 auto;
}

.info-widget {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 900;
    width: fit-content;
    min-width: 160px;
    max-width: 280px;
    background: linear-gradient(135deg, #3388FF 0%, #2a6dcc 100%);
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(51, 136, 255, 0.2);
    transition: all 0.3s ease;
    cursor: pointer;
    animation: widgetPulse 5s ease-in-out infinite;
    overflow: hidden;
    white-space: nowrap;
}

@keyframes widgetPulse {
    0%, 80%, 100% {
        transform: translateY(0);
        box-shadow: 0 4px 20px rgba(51, 136, 255, 0.2);
    }
    90% {
        transform: translateY(-2px);
        box-shadow: 0 6px 25px rgba(51, 136, 255, 0.3);
    }
}

.info-widget-content {
    padding: 12px 16px;
    position: relative;
}

.info-widget::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.1) 100%);
}

.info-widget-title {
    font-weight: 600;
    color: white;
    font-size: 16px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.info-widget-title::before {
    content: '💡';
    font-size: 18px;
}

.info-widget-description {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.95);
    line-height: 1.4;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    margin-top: 2px;
    white-space: normal;
}

.info-widget:hover {
    animation: none;
    transform: translateY(-2px);
    box-shadow: 0 6px 25px rgba(51, 136, 255, 0.3);
    width: 280px !important;
}

.info-widget:hover .info-widget-description {
    opacity: 1;
    max-height: 200px;
}

@media (max-width: 768px) {
    .info-widget {
        bottom: 10px;
        right: 10px;
        left: auto;
        max-width: 240px;
        margin: 0;
    }
    
    .info-widget-content {
        padding: 10px 14px;
    }
    
    .info-widget-title {
        font-size: 15px;
    }
    
    .info-widget-description {
        font-size: 14px;
    }
}

/* Promo Popup Styles */
.promo-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(8px);
}

.promo-popup {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 28px;
    position: relative;
    width: 90%;
    max-width: 420px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    animation: slideUp 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    overflow: hidden;
}

.promo-popup::before {
    content: '';
    position: absolute;
    inset: 0;
    background: 
        radial-gradient(circle at 0% 0%, rgba(var(--colorPrimaryRGB), 0.1) 0%, transparent 50%),
        radial-gradient(circle at 100% 100%, rgba(var(--colorTertiaryRGB), 0.1) 0%, transparent 50%);
    pointer-events: none;
}

.promo-popup-close {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 24px;
    color: #666;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    z-index: 2;
}

.promo-popup-close:hover {
    color: #333;
    background-color: transparent !important;
    transform: scale(1.1);
}

.promo-popup-content {
    text-align: center;
    position: relative;
    z-index: 1;
}

.promo-popup-content h2 {
    color: black;
    font-size: 42px;
    font-weight: 800;
    margin-bottom: 24px;
    letter-spacing: -0.5px;
    position: relative;
    display: inline-block;
}

.promo-divider {
    display: none;
}

.promo-text {
    color: #333;
    font-size: 17px;
    line-height: 1.6;
    margin-bottom: 12px;
    position: relative;
}

.promo-popup-button {
    background-color: var(--colorTertiary);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 14px 32px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 16px;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.promo-popup-button:hover {
    background-color: var(--colorTertiary);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(var(--colorTertiaryRGB), 0.2);
}

@keyframes slideUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 480px) {
    .promo-popup {
        width: 95%;
        padding: 24px 20px;
        border-radius: 10px;
    }

    .promo-popup-content h2 {
        font-size: 36px;
    }

    .promo-text {
        font-size: 16px;
    }

    .promo-popup-button {
        padding: 12px 28px;
        width: 100%;
    }
}
/* Athlete List Page */
.athlete-wrapper {
    padding: var(--marginPageTop) var(--marginPageSide);
}

.athlete-top-row {
    text-align: center;
    padding: 20px 20px;
    background-color: var(--colorBG2);
    border-radius: 20px;
    max-width: 800px;
    margin: 0 auto 0.5rem auto;
}

.athlete-top-header {
    font-size: var(--textSizeHuge);
    margin-bottom: 15px;
}

.athlete-top-intro {
    font-size: min(var(--textSizeSubtitle),4vw);
    color: gray;
}

.athlete-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    max-width: var(--widthContent_MAX);
    margin: auto;
    gap: 1rem;
}

.athlete-entry {
    width: min(300px,42vw);
    background-color: white;
    border-radius: 20px;
    transition: all 300ms ease-in-out;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    opacity: 0; /* Ensure the element is hidden initially */
    transform: translateY(20px); /* Optional: Add a slight upward movement */
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.athlete-entry:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    opacity: 0.8;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.athlete-entry.fade-in {
    animation: fadeIn 0.5s forwards;
}

.athlete-entry-image-container {
    position: relative;
}

.athlete-entry-image {
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: cover;
    position: relative;
    overflow-clip-margin: unset;
}

.athlete-entry-image-overlay {
    position: absolute;
    background: linear-gradient(to bottom,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.6));
    width: 100% !important;
    height: 100% !important;
    top: 0px;
    right: 0px;
}

.athlete-entry-promo {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--colorTertiary);
    color: white;
    padding: min(2vw, 8px) min(6vw, 30px);
    font-size: min(3.2vw, 16px);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transform: translateY(0) rotate(0deg) skew(-20deg);
    transform-origin: top right;
    z-index: 1;
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);
}

.athlete-entry-promo::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
    z-index: -1;
    transform: translateX(min(4vw, 20px));
}

/* Media query for smaller screens */
@media (max-width: 480px) {
    .athlete-entry-promo {
        padding: 6px 20px;
        font-size: 14px;
        transform: translateY(0) rotate(0deg) skew(-15deg); /* Less extreme angle on mobile */
    }
    
    .athlete-entry-promo::before {
        transform: translateX(15px);
    }
}

.athlete-entry-info {
    position: absolute;
    bottom: min(2vw,1rem);
    left: min(2vw,1rem);
    font-size: min(4vw,var(--textSizeTitle));
    /* color: white; */
}

.athlete-name {
    font-size: min(4vw,var(--textSizeTitle));
    font-weight: bold;
    color: white;
}

.athlete-icon {
    height: min(3vw,var(--textSizeBody));
    stroke: var(--colorText2);
}

.athlete-sport {
    margin-bottom: min(0.8vw,0.5rem);
    font-size: min(3vw,var(--textSizeBody));
    line-height: var(--textSizeBody);
    color: var(--colorText2);
}

.athlete-accolade {
    font-size: min(3vw,var(--textSizeBody));
    line-height: var(--textSizeBody);
    font-style: italic;
    color: var(--colorText2);
}

/* Athlete Suggestion Form */
.athlete-suggestion-section {
    margin-top: 40px;
    text-align: center;
    padding: 20px 20px;
    background-color: var(--colorBG2);
    border-radius: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.athlete-suggestion-section::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
}

.athlete-suggestion-title {
    font-size: var(--textSizeHeader);
    margin-bottom: 15px;
}

.athlete-suggestion-text {
    font-size: var(--textSizeBody);
    margin-bottom: 25px;
    color: gray;
}

.athlete-suggestion-form {
    display: flex;
    gap: 10px;
    max-width: 500px;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
}

.athlete-suggestion-input {
    flex: 1;
    min-width: 200px;
    padding: 10px 15px;
    border: 1px solid var(--colorBorder);
    border-radius: 8px;
    font-size: var(--textSizeBody);
}

.athlete-suggestion-button {
    padding: 12px 30px;
    background-color: var(--colorPrimary) !important;
    border: none !important;
    border-radius: 12px;
    color: white;
    font-size: var(--textSizeBody);
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.athlete-suggestion-button:hover {
    background-color: var(--colorPrimary);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.athlete-suggestion-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.athlete-suggestion-success {
    margin-top: 15px;
    color: var(--colorSuccess);
    font-size: var(--textSizeBody);
    animation: fadeIn 0.3s ease-in;
}
/* Make sure this matches the width of the page content */
@media (max-width: 900px) {
    .view-page-format {
        padding: 0 !important;
    }
    .view-header, .view-video-col, .view-response-feedback, .view-response-donation {
        border-radius: 0px !important;
    }
    .view-header {
        padding: 20px 10px !important;
    }
    .view-response-container {
        flex-direction: column;
    }
    .view-video-col {
        flex: none !important;
        width: 100%;
    }
    .view-form {
        border-radius: 0px !important;
        margin-bottom: 0px !important;
        padding: 0 !important;
        box-shadow: none;
    }
}
.view-page-format {
    justify-content: center;
    display: flex;
    padding: var(--marginPageTop) var(--marginPageSide);
    position: relative;
  }
.view-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: var(--widthContent_MAX);
    opacity: 0;
    animation: smooth-appear 1s ease forwards;
    background-color: transparent;
    padding: 10px;
}
.view-header {
    font-size: min(5vw,var(--textSizeTitle));
    font-weight: bold;
    text-align: center;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: min(5vw,15px);
    margin-bottom: 5px;
}
.view-response-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    margin: 5px 0;
}
.view-video-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: box-shadow 0.3s ease;
}
.view-video-col:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
.view-video-title {
    font-size: min(4.5vw,var(--textSizeSubtitle));
    color: #333;
    margin-top: 15px;
    text-align: center;
    font-weight: 600;
    width: 100%;
    padding: 0 15px;
}
.view-video {
    width: 100%;
    aspect-ratio: 16/9;
    background-color: #000;
}
.view-text-message {
    font-size: min(3vw,var(--textSizeBody));
    margin: 5px 10px;
    margin-top: 16px;
    background-color: #f9f9f9;
    border-left: 4px solid #ff9900;
    font-style: italic;
}
.view-response-donation {
    padding: min(5vw,20px);
    /* width: 100%; */
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 5px 0;
}
.view-response-feedback {
    padding: min(5vw,20px);
    width: 100%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
}
.view-response-title {
    font-size: min(4.5vw,var(--textSizeSubtitle));
    text-align: center;
}

/* Add a modifier class for left alignment */
.view-response-title.text-left {
    text-align: left;
}

.view-response-text {
    font-size: min(3.5vw,var(--textSizeBody)) !important;
}
.view-respond-button {
    align-self: center;
    background-color: white !important;
    color: var(--colorPrimary) !important;
    border-radius: 15px !important;
    border: none !important;
    width: 100%;
    padding: 12px 25px !important;
    margin: 5px 0;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.view-respond-button:hover {
    background-color: var(--colorPrimary) !important;
    color: white !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
.view-star-rating {
    display: flex;
    flex-direction: row;
}
.view-rating-text {
    cursor: pointer;
    font-weight: bold;
    font-size: min(3.5vw,var(--textSizeBody));
    margin-top:5px;
    color: black;
    text-decoration: underline;
}
.view-star {
    font-size: 2rem;
    cursor: pointer;
    color: grey;
}
.view-star.selected {
    color: gold;
}
.view-feedback-button, .view-payment-button {
    color: var(--colorText3) !important;
    background-color: #04AA6D;
    border-radius: 10px !important;
    border: 0px !important;
    font-size: min(3.5vw,var(--textSizeBody)) !important;
    font-family: var(--fontPrimary) !important;
    padding: 0.4rem 1.1rem !important;
    display: block;
    margin-top: 20px;
}
.view-payment-button {
    background-color: var(--colorPrimary) !important;
    transition: filter 0.2s ease-in-out !important;
}
.view-payment-button:hover {
    filter: brightness(0.8) !important;
}
.view-feedback-button:hover {
    background-image: linear-gradient(rgb(0 0 0/30%) 0 0) !important;
}
.view-confirm-button {
    background-color: #04AA6D !important; /* Bootstrap's danger red */
    /* margin-bottom: 15px; */
    width: 100px;
    padding: 5px !important;
    border-radius: 10px !important;
}
.view-cancel-button {
    background-color: #dc3545 !important; /* Bootstrap's danger red */
    /* margin-bottom: 15px; */
    width: 100px;
    padding: 5px !important;
    border-radius: 10px !important;
}

.view-cancel-button:hover {
    background-color: #c82333 !important;
}

/* Update the donation-subcontainer styles */
.view-donation-subcontainer {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    align-items: center;
    flex-wrap: wrap;
}

/* Add styles for the price display */
.price-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--textSizeBody);
    color: var(--colorPrimary);
    font-weight: 600;
    text-align: center;
    margin: 15px 0;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

/* Add new styles for the split sections */
.view-split-container {
    display: flex;
    gap: min(2vw,20px);
    align-items: stretch;
    position: relative;
    margin-top: 15px;
    justify-content: center;
}

.view-split-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px;
    background-color: white;
    max-width: 400px;
    border-radius: 10px;
    border: 2px solid var(--colorTertiary);
    border-top-width: 7px;
}

.view-split-section.account-section {
    border-color: var(--colorTertiary);
    border-top-color: var(--colorTertiary);
}

.view-split-section.request-section {
    border-color: var(--colorPrimary);
    border-top-color: var(--colorPrimary);
}

.view-split-subtitle {
    font-size: min(4.5vw,var(--textSizeSubtitle));
    margin-bottom: 10px;
    font-weight: bold;
}

.view-split-section.account-section .view-split-subtitle {
    color: var(--colorTertiary);
}

.view-split-section.request-section .view-split-subtitle {
    color: var(--colorPrimary);
}

.view-split-description {
    font-size: min(3vw,var(--textSizeBody));
    color: var(--colorText1);
    margin-bottom: auto;
    padding: 0 10px;
}

.view-split-or {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--colorText1);
    padding: 10px;
    font-size: min(4.5vw,var(--textSizeSubtitle));
}

@media (max-width: 900px) {
    .view-split-container {
        flex-direction: column;
    }
    
    .view-split-or {
        padding: 0 !important;
    }
}

.view-feedback-container {
    display: flex;
    gap: 10px;
    align-items: stretch;
}

.view-feedback-container > * {
    flex: 1;
}

@media (max-width: 900px) {
    .view-feedback-container {
        flex-direction: column;
    }
}

@media (max-width: 500px) {
    .demo-form {
      border-radius: 0px !important;
      margin-bottom: 0px !important;
      margin: 0px !important;
      padding-left: var(--marginPageSide) !important;
      padding-right: var(--marginPageSide) !important;
      width: 100% !important;
    }
  }
  .demo-form {
    display: flex;
    flex-direction: column;
    width: var(--widthContent_MAX);
    opacity:0;
    animation: smooth-appear 1.5s ease forwards;
    background-color: white;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 30px 30px;
  }
  .demo-title {
    text-align: center;
    margin-bottom: min(2vw,15px);
    font-size: min(4vw,var(--textSizeTitle));
  }
  .demo-subtitle {
    text-align: center;
    margin-bottom: 20px;
    font-size: min(3.5vw,var(--textSizeBody));
  }
  .demo-subtitle a {
    color: #55AAff;
    font-weight: bold;
    text-decoration: none;
  }
  .demo-subtitle a:hover {
    text-decoration: underline;
  }
  .animation-controls {
    margin: 20px 0;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  .animation-mode-selector,
  .speed-control {
    margin-bottom: 15px;
  }
  .animation-mode-selector label,
  .speed-control label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .animation-mode-selector select,
  .speed-control input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
  }
  .speed-control input {
    width: 100px;
  }
  .message-group {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  .message-hidden {
    opacity: 0;
    transform: scale(0.8);
    pointer-events: none;
  }
  .custom-video-container {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  .conversation-video {
    width: 100%;
    border-radius: 8px;
    background-color: #000;
    display: block;
  }
  .conversation-video::-webkit-media-controls-panel {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0 0 8px 8px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .conversation-video.playing::-webkit-media-controls-panel {
    opacity: 1;
  }
  .conversation-video::-webkit-media-controls-play-button {
    background-color: white;
    border-radius: 50%;
  }
  .conversation-video::-webkit-media-controls-timeline {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
  }
  .conversation-video::-webkit-media-controls-current-time-display,
  .conversation-video::-webkit-media-controls-time-remaining-display {
    color: white;
  }
  .play-button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  .play-button-overlay:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .play-icon {
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #000;
    transition: transform 0.2s ease;
  }
  .play-button-overlay:hover .play-icon {
    transform: scale(1.1);
  }
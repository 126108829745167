@media (max-width: 500px) {
    .signup-container {
        padding: 0 !important;
    }
    .signup-code-form, .signup-form {
      border-radius: 0px !important;
      margin-bottom: 0px !important;
      margin: 0px !important;
      padding-left: var(--marginPageSide) !important;
      padding-right: var(--marginPageSide) !important;
      width: 100% !important;
    }
  }
.signup-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    min-height: 70vh;
}

.signup-code-form,
.signup-form {
    width: 100%;
    max-width: 500px;
    padding: 30px;
    height: fit-content;
    background-color: white;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.signup-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.achievement-entry,
.interest-entry {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

button {
    background-color: var(--colorPrimary);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: rgba(var(--colorPrimaryRGB), 0.8);
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

@media (max-width: 500px) {
    .signup-code-form,
    .signup-form {
        border-radius: 0;
        padding: 20px;
    }
}

.text-muted {
    display: flex;
    margin-top: 0 !important;
    margin-bottom: 5px !important;
}

.signup-picture {
    overflow-clip-margin: unset;
}
.signup-required {
    color: red;
    margin-left: 3px;
}

/* Join Page Styles */
.join-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    max-width: 1200px;
    min-height: 75vh;
    margin: 0 auto;
}

.join-header {
    text-align: center;
    margin-bottom: min(30px, 5vw);
    color: var(--colorPrimary);
}

.join-cards-container {
    display: flex;
    gap: clamp(16px, 3vw, 30px);
    justify-content: center;
    width: 100%;
}

.join-card {
    flex: 1;
    min-width: 0;
    max-width: 300px;
    padding: min(25px, 4vw) min(25px, 2vw);
    background-color: white;
    background-color: rgba(var(--colorPrimaryRGB), 0.1);
    border-radius: 15px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.join-card:hover {
    background-color: rgba(var(--colorPrimaryRGB), 0.1);
    box-shadow: 0 4px 8px rgba(var(--colorPrimaryRGB), 0.2);
}

.join-card-icon {
    width: 80%;
    aspect-ratio: 1/1;
    margin-bottom: clamp(12px, 2vw, 20px);
}

.join-card-title {
    margin-bottom: clamp(8px, 1.5vw, 10px);
    font-weight: bold;
    font-size: min(25px, 5vw);
    color: var(--colorPrimary);
}

.join-card-subtitle {
    color: #666;
    margin: 0;
    font-size: min(16px, 3.5vw);
}

.join-redirect {
    margin-top: 20px;
    text-align: center;
}

.join-redirect-link {
    color: #55AAff !important;
    text-decoration: none;
}

.join-redirect-link:hover {
    text-decoration: underline;
}

.no-top-margin {
    margin-top: 0 !important;
}

.welcome-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
    opacity: 0;
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

.welcome-popup {
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    max-width: 600px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    transform: scale(0.95);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.welcome-popup h2 {
    color: var(--colorPrimary);
    margin-bottom: 20px;
    text-align: center;
}

.welcome-popup p {
    margin-bottom: 15px;
    line-height: 1.6;
}

.welcome-popup-button {
    width: 100%;
    padding: 12px;
    background-color: var(--colorPrimary);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.welcome-popup-button:hover {
    background-color: rgba(var(--colorPrimaryRGB), 0.8);
}

.welcome-popup-overlay.animate-in {
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
}

.welcome-popup.animate-in {
    transform: scale(1);
    opacity: 1;
}

.request-wrapper {
    justify-content: center;
    display: flex;
}
/* Make sure this matches the width of the form */
@media (max-width: 500px) {
  .contact-page-format {
      padding: 0;
  }
  .request-form {
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    margin: 0px !important;
    padding-left: var(--marginPageSide) !important;
    padding-right: var(--marginPageSide) !important;
    width: 100% !important;
  }
}
  .request-form {
    display: flex;
    flex-direction: column;
    width: 100%; /* Use full width */
    max-width: 500px; /* Set a max-width */
    min-height: 700px;
    opacity: 0;
    animation: smooth-appear 1.5s ease forwards;
    background-color: white;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 30px 30px;
    box-sizing: border-box; /* Ensure padding is included in width */
  }
  .request-form-content {
    display: flex;
    flex-direction: column;
    opacity:0;
    animation: smooth-appear 0.5s ease forwards !important;
  }
  .request-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: auto !important;
    width: 100%;
  }
  .request-header {
    text-align: center;
    font-size: var(--textSizeHeader);
    font-weight: bold;
  }
  .request-title {
    font-size: var(--textSizeSubtitle);
    margin: var(--paddingSubtitle) 0;
    color: var(--colorPrimary);
    font-weight: bold;
    display: inline-block;
  }
  .request-collapsible-content {
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .request-collapsed {
    max-height: 0;
  }
  
  .request-expanded {
    max-height: 1000px; /* Adjust this value based on your content height */
  }
  .request-number {
    display: inline-block;
    color: var(--colorPrimary);
    width: 40px;
    aspect-ratio: 1;
    text-align: center;
    padding-top: 2px;
    margin-right: 10px;
    border: solid 2px var(--colorPrimary);
    border-radius: 900px;
  }
  .request-instructions-container {
    display: flex;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.75s ease-out, transform 0.75s ease-out;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .request-instructions-container.fade-in {
    animation: fadeIn 0.75s forwards;
  }
  .request-instructions-icon {
    height: min(7vw,30px);
    width: auto;
    margin-right: min(3vw,15px);
    color: var(--colorPrimary);
  }
  .request-instructions {
    margin-bottom: min(3.5vw,var(--paddingBody));
    font-size: min(4vw,var(--textSizeBody));
  }
  .request-subtitle {
    margin-bottom: var(--paddingBody);
  }
  .request-athlete-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .request-profile-picture {
    width: 50px;
    aspect-ratio: 1;
    object-fit: cover;
    overflow-clip-margin: unset;
    border-radius: 90%;
    margin-left: min(3vw,20px); /* Space between dropdown and image */
  }
  .request-athlete-dropdown,
  .request-country-dropdown,
  .request-age-dropdown {
    width: 300px !important;
    margin-bottom: 15px;
  }
  .request-type-parent {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .request-type-button {
    background-color: transparent !important;
    border: none;
  }
  .request-type-button:focus {
    border: solid 4px var(--colorPrimary);
    border-radius: 20px;
    color: var(--colorPrimary);
  }
  .request-type-video, .request-type-text {
    display: inline-block;
    font-size: (4vw,var(--textSizeBody));
  }
  .request-type-icon {
    height: min(25vw,30px);
    width: auto;
    margin-left: 10px;
  }
  .request-country-dropdown {
    margin-bottom: 15px;
  }
  .request-video-upload {
    display: flex;
    flex-direction: column;
  }
  .request-video-button {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
  }
  .request-video-button + label {
    font-size: var(--textSizeBody);
    color: var(--colorPrimary);
    border: solid 2px var(--colorPrimary);
    border-radius: 10px;
    padding: 0.2rem 0.8rem;
    max-width: 150px;
    margin-bottom: 20px;
    text-align: center;
    display: inline-block;
  }
  .request-video-button:focus + label,
  .request-video-button + label:hover {
      background-color: var(--colorPrimary);
      color: var(--colorText3);
      transition: ease-out 0.3s;
  }
  .request-video-thumbnail {
    width: 150px;
    max-height: 150px;
    margin-bottom: 20px;
    background-color: black;
  }
  .request-button {
    background-color: var(--colorPrimary) !important;
    color: var(--colorText3) !important;
    border-radius: 30px !important;
    border: 0px solid var(--colorTertiary) !important;
    font-size: var(--textSizeBody) !important;
    font-family: var(--fontPrimary) !important;
    margin-top: 20px;
    margin-bottom: 0 !important;
    padding: 0.4rem 1.1rem !important;
    transition: background-color 0.3s ease;
    height: 40px; /* Add this line to set a fixed height */
    display: flex; /* Add this line */
    align-items: center; /* Add this line */
    justify-content: center; /* Add this line */
  }
  .request-button:disabled {
    background-color: rgba(var(--colorPrimaryRGB), 0.5) !important;
    cursor: not-allowed;
  }
  .request-info-header {
    margin-bottom: 5px;
  }
  .request-info {
    max-width: 300px;
    border-radius: 5px !important;
    border: 1px solid var(--colorText2) !important;
    margin-bottom: 20px;
    color: var(--colorText1);
  }
  .request-message {
    background-color: var(--engineeringCard);
    max-width: 100%;
    max-height: 300px;
    border-radius: 5px !important;
    border: 1px solid var(--colorText2) !important;
    margin-bottom: 20px;
    color: var(--colorText1);
  }

  .StripeElement {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }

  .price-display {
    display: flex;
    justify-content: space-between; /* Align items to the left and right */
    align-items: center;
    font-size: var(--textSizeBody);
    color: var(--colorPrimary);
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .discount-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .discount-input {
    flex: 1;
    padding: 5px;
    font-size: var(--textSizeBody);
    border: 1px solid var(--colorPrimary);
    border-radius: 5px;
    margin-right: 10px;
  }
  .discount-button {
    padding: 5px 20px;
    font-size: var(--textSizeBody);
    background-color: var(--colorPrimary);
    color: var(--colorText3);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .discount-button:hover {
    background-color: rgba(var(--colorPrimaryRGB), 0.8);
  }
  .discount-message {
    color: green;
    font-weight: bold;
    margin-bottom: 20px;
    animation: appear 0.3s ease-out forwards; /* Apply the animation */
  }

  @keyframes appear {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .donation-container {
    padding-bottom: 20px;
    border-radius: 5px;
  }

  .donation-subcontainer {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    align-items: center;
  }

  .donation-button {
    padding: 5px 10px;
    background-color: transparent;
    color: var(--colorPrimary);
    border: 1px solid var(--colorPrimary);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .donation-button.selected {
    background-color: var(--colorPrimary);
    color: white;
  }

  .donation-input {
    width: 130px;
    padding: 5px;
    border: 1px solid var(--colorPrimary);
    border-radius: 5px;
  }

  .donation-input.selected {
    background-color: var(--colorPrimary);
    color: white;
  }

/* Add styles for the confirmation form */
.confirmation-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.confirmation-form .summary {
  margin-bottom: 20px;
}


.cancel-button {
  margin-top: 10px;
  background-color: transparent;
  color: var(--colorPrimary);
  border: 2px solid var(--colorPrimary);
  border-radius: 30px; /* Match the request-button border-radius */
  font-size: var(--textSizeBody); /* Match the request-button font size */
  font-family: var(--fontPrimary); /* Match the request-button font family */
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 40px; /* Match the request-button height */
  display: flex; /* Add this line */
  align-items: center; /* Add this line */
  justify-content: center; /* Add this line */
}
.cancel-button:hover {
  background-color: #f0f0f0;
}

.required-asterisk {
  color: red;
  margin-left: 2px;
}

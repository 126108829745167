.legal-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.legal-container h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.legal-container .last-updated {
  font-style: italic;
  color: #666;
  margin-bottom: 20px;
}

.legal-container h2 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.legal-container ol {
  list-style-type: decimal !important;
  padding-left: 20px;
  counter-reset: item;
}

.legal-container ol li {
  display: block;
  margin-bottom: 5px;
  counter-increment: item;
}

.legal-container ol li:before {
  content: counter(item) ". ";
  display: inline-block;
  width: 1.5em;
  margin-left: -1.5em;
}

.legal-container a {
  color: var(--colorTertiary) !important;
  text-decoration: underline !important;
}

.legal-container a:hover {
  text-decoration: underline;
}

.legal-agreement {
  font-size: 12px;
  color: #666;
  margin-top: 10px;
}

.legal-agreement a {
  color: #007bff;
  text-decoration: none;
}

.legal-agreement a:hover {
  text-decoration: underline;
}

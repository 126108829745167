.contact-page-format {
    justify-content: center;
    display: flex;
    padding: var(--marginPageTop) var(--marginPageSide);
}
/* Make sure this matches the width of the form */
@media (max-width: 500px) {
  .contact-page-format {
    padding: 0; /* Set to 0 for smaller screens */
  }
  .contact-form {
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    margin: 0px !important;
    padding-left: var(--marginPageSide) !important;
    padding-right: var(--marginPageSide) !important;
    width: 100% !important;
  }
}
.contact-form {
  display: flex;
  flex-direction: column;
  width: 500px;
  min-height: 700px;
  opacity:0;
  animation: smooth-appear 1.5s ease forwards;
  background-color: white;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 30px 30px;
}
.contact-title {
  text-align: center;
  font-size: var(--textSizeHeader);
  padding-bottom: var(--paddingTitle);
  color: var(--colorText1);
}
.contact-line {
  color: var(--colorPrimary);
  width: 60%;
  /* margin: 2em auto; */
  margin: auto;
  border: 2px solid;
  border-radius: 100rem;
  opacity: 1.0;
}
.contact-description {
  padding-top: var(--paddingTitle);
}
.contact-redirect {
  margin-top: 10px;
  margin-bottom: 20px;
}
.contact-redirect-link {
  color: #55AAff !important;
}
.contact-athlete-radio {
  width: 110px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.contact-info-header {
  margin-bottom: 5px;
}
.contact-info {
  max-width: 300px;
  border-radius: 5px !important;
  border: 1px solid var(--colorText2) !important;
  margin-bottom: 20px;
  color: var(--colorText1);
}
.contact-message {
  background-color: var(--engineeringCard);
  max-width: 100%;
  max-height: 300px;
  border-radius: 5px !important;
  border: 1px solid var(--colorText2) !important;
  margin-bottom: 20px;
  color: var(--colorText1);
}
.contact-button {
  background-color: var(--colorPrimary) !important;
  color: var(--colorText3) !important;
  border-radius: 30px !important;
  border: .1rem solid var(--colorPrimary) !important;
  padding: 5px;
  font-size: 16px !important;
  font-family: var(--fontPrimary) !important;
  font-weight: bold;
  width: 90px;
  margin-top: 10px;
  /* Transition Stuff */
  box-shadow: inset 0 0 0 0 var(--colorPrimary);
  -webkit-transition: ease-out 0.3s;
  -moz-transition: ease-out 0.3s;
  transition: ease-out 0.3s;
}
.contact-button:hover{
  background-color: var(--colorText1) !important;
  color: var(--colorText3) !important;
  border: .1rem solid var(--colorText1) !important;
  /* Transition Stuff */
  box-shadow: inset 400px 0 0 0 var(--colorText1);
  transition: ease-out 0.3s;
}
.contact-received-text {
  opacity:0;
  animation: smooth-appear 2s ease forwards;
}
.contact-received-title {
  text-align: center;
  font-size: var(--textSizeTitle);
  color: var(--colorText1);
}
.contact-received-text {
  font-size: var(--textSizeBody);
  margin: 1rem 0;
}
.contact-back-button {
  /* background-color: transparent !important; */
  align-self: center;
  background-color: var(--colorPrimary) !important;
  color: var(--colorText3) !important;
  border-radius: 30px !important;
  border: 0px solid var(--colorText3) !important;
  width: 100px;
  /* Transition Stuff */
  box-shadow: inset 0 0 0 0 var(--colorPrimary);
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.contact-back-button:hover{
  background-color: var(--colorText1) !important;
  color: var(--colorText3) !important;
  border: 0px solid var(--colorText1) !important;
  /* Transition Stuff */
  box-shadow: inset 400px 0 0 0 var(--colorText1);
  transition: ease-out 0.5s;
}
.signin-redirect {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.signin-redirect-link {
  color: #55AAff !important;
  text-decoration: none;
  display: block;
  text-align: left;
}
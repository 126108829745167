@media (max-width: 500px) {
    .roster-form {
      border-radius: 0px !important;
      margin-bottom: 0px !important;
      margin: 0px !important;
      padding-left: var(--marginPageSide) !important;
      padding-right: var(--marginPageSide) !important;
      width: 100% !important;
    }
}
.roster-form {
    display: flex;
    flex-direction: column;
    width: 500px;
    min-height: 700px;
    opacity:0;
    animation: smooth-appear 1.5s ease forwards;
    background-color: white;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 30px 30px;
}
.roster-logo {
    text-align: center;
    filter: invert(23%) sepia(8%) saturate(3903%) hue-rotate(173deg) brightness(99%) contrast(90%);
    height: 60px;
    width: auto;
}
.roster-banner {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(var(--colorPrimaryRGB),0.2);
    color: var(--colorPrimary);
    border-radius: 15px;
    padding: 8px;
}
.roster-icon {
    width: 30px !important;
    height: 30px !important;
    margin-left: 10px;
    margin-right: 20px;
}
.roster-title {
    font-size: min(4vw,var(--textSizeBody));
    line-height: calc(min(6vw,var(--textSizeBody))*1.25);
}
.roster-text {
    font-size: min(4vw,var(--textSizeBody));
    margin-bottom: var(--paddingBody);
}
.roster-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: min(3vw,25px);
    margin-left: min(3vw,30px);
    margin-right: min(3vw,30px);
}
.roster-entry {
    width: min(300px,42vw);
    border-radius: 20px;
    /* border: 5px solid var(--colorTertiary); */
    transition: all 300ms ease-in-out;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.roster-entry:hover {
    transform: translateY(-10px);
    opacity: 0.8;
}
.roster-entry-image-container {
    position: relative;
}
.roster-entry-image {
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: cover;
    position: relative;
    overflow-clip-margin: unset;
    overflow: visible;
}
.roster-entry-image-overlay {
    position: absolute;
    background: linear-gradient(to bottom,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.6));
    width: 100% !important;
    height: 100% !important;
    top: 0px;
    right: 0px;
}
.roster-entry-info {
    position: absolute;
    bottom: min(3vw,15px);
    width: 100%;
}
.roster-name {
    margin: 0 5px;
    font-size: min(4vw,var(--textSizeSubtitle));
    text-align: center;
    color: white;
}
/* Top Navbar -----------------------------------------------------------------------------------*/
@media (max-width: 580px) {
  .navElements {
    margin: 0px !important;
  }
  .navElementLinks {
    margin: 0px !important;
  }
}
.nav-wrapper {
  background-color: var(--colorPrimary);
  padding-left: var(--marginPageSide);
  padding-right: var(--marginPageSide);
}
.nav-bar {
  /* background-color: var(--colorPrimary); */
  max-width: var(--widthContent_MAX);
  margin-left: auto;
  margin-right: auto;
}
.navVertAlign {
  text-align: right;
  align-items: center !important;
}
/* .nav-logo {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  transition: filter 0.3s ease-in-out;
  height: 40px;
  width: auto;
}
.nav-logo:hover {
  filter: invert(73%) sepia(26%) saturate(4861%) hue-rotate(3deg) brightness(95%) contrast(101%);
} */
.nav-logo {
  height: 40px;
  width: auto;
}
.navName {
  color: var(--colorText3);
  font-family: var(--fontPrimary);
  font-size: var(--textSizeTitle);
  transition: ease-in-out 300ms;
}
.navName:hover {
  color: var(--colorTertiary);
}
.nav-hamburger{
  margin-right: var(--marginPageSide);
  background-color: var(--colorHamburger) !important;
  transition: all 300ms ease-in-out;
}
.nav-hamburger:hover {
  background-color: var(--engineeringSkill) !important;
}
.navElements {
  justify-content: end;
  margin-left: var(--marginPageSide);
}
.navElementLinks {
  margin-left: var(--marginPageSide);
}
.navElement {
  color: var(--colorText3);
  opacity: 0.7;
  font-family: var(--fontPrimary);
  font-size: var(--textSizeBody);
  transition: ease-in-out 300ms;
}
.navElement:hover {
  color: var(--colorTertiary);
  opacity: 1;
}
.navButton {
  background-color: transparent !important;
  color: var(--colorTertiary) !important;
  border-radius: 30px !important;
  border: 2px solid var(--colorTertiary) !important;
  padding-left: 1rem !important;
  padding-right: 1rem!important;
  font-size: var(--textSizeBody) !important;
  /* font-weight: bold !important; */
  font-family: var(--fontPrimary) !important;
  /* Transition Stuff */
  box-shadow: inset 0 0 0 0 var(--colorTertiary);
  -webkit-transition: ease-in-out 0.5s;
  -moz-transition: ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
}
.navButton:hover {
  background-color: var(--colorTertiary) !important;
  color: var(--colorPrimary) !important;
  border: 5px solid white;
  /* Transition Stuff */
  box-shadow: inset 400px 0 0 0 var(--colorTertiary);
  transition: ease-in-out 0.5s;
}
/* Loading Ring -----------------------------------------------------------------------------------*/

.loader {
  display: flex;
  align-items: flex-end;
  font-size: 50px;
  height: 70px;
  font-weight: bold;
  position: relative;
  color: var(--colorPrimary);
  animation: textColorChange 2s steps(1) infinite;
}

/* The "i" stick */
.loader::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 25px;  /* Slightly taller rectangle */
  background-color: var(--colorPrimary);
  margin-right: 4px;
  margin-bottom: 21px;  /* Moved rectangle higher up */
  animation: colorChange 2s steps(1) infinite;
}

/* The bouncing dot */
.loader::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0px;  /* Moved up to match rectangle's new position */
  width: 7px; 
  height: 6px;
  border-radius: 50%;
  background-color: var(--colorPrimary);
  animation: bounce 1s infinite,
             colorChange 2s steps(1) infinite;  /* Made color change instant */
}

@keyframes bounce {
  0%, 100% {
    bottom: 45px;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  50% {
    bottom: 60px;
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
}

@keyframes colorChange {
  0%, 100% {
    background-color: var(--colorPrimary);
  }
  50% {
    background-color: var(--colorTertiary);
  }
}

@keyframes textColorChange {
  0%, 100% {
    color: var(--colorPrimary);
  }
  50% {
    color: var(--colorTertiary);
  }
}


.loader-ring {
  color: var(--colorPrimary);
}
.loader-ring,
.loader-ring div {
  box-sizing: border-box;
}
.loader-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.loader-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-button {
  height: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 5px solid white;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}
@keyframes l20-1{
   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{ 
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}



  /* Confirmation Modal -----------------------------------------------------------------------------------*/
  .confirmation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .confirmation-modal {
    background-color: white;
    width: min(80vw,400px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    opacity:0;
    animation: smooth-appear 0.3s ease forwards;
  }
  .confirmation-modal-title {
    color: var(--colorText3);
    font-size: min(5.5vw,var(--textSizeTitle));
    padding: min(1vw,5px) 15px;
    border-radius: 10px 10px 0 0;
    background-color: var(--colorPrimary);
    text-align: center;
  }
  .confirmation-modal-message {
    color: var(--colorText1);
    margin: min(3vw,15px) 15px;
    font-size: min(3.5vw,var(--textSizeBody));
  }
  .confirmation-modal-textarea {
    width: 90%;
    margin: 0 15px;
    font-size: min(3.5vw,1rem);
    border: solid 1px var(--colorText2);
    border-radius: 5px;
  }
  .confirmation-modal-button-container {
    margin-top: '20px';
    justify-content: center;
    gap: 15px;
    display: flex;
    flex-wrap: wrap;
    margin: min(3vw,20px) 0;
  }
  .confirmation-modal-confirm, .confirmation-modal-cancel {
    background-color: var(--colorPrimary) !important;
    color: var(--colorText3) !important;
    border-radius: 30px !important;
    border: .1rem solid var(--colorPrimary) !important;
    padding: 5px 20px !important;
    font-size: min(3.5vw,var(--textSizeBody)) !important;
    font-family: var(--fontPrimary) !important;
    font-weight: bold;
    /* Transition Stuff */
    box-shadow: inset 0 0 0 0 var(--colorPrimary);
    -webkit-transition: ease-out 0.3s;
    -moz-transition: ease-out 0.3s;
    transition: ease-out 0.3s;
  }
  .confirmation-modal-cancel {
    color: var(--colorPrimary) !important;
    background-color: transparent !important;
    border: 2px solid var(--colorPrimary) !important;
  }
  .confirmation-modal-confirm:hover, .confirmation-modal-cancel:hover {
    background-image: linear-gradient(rgb(0 0 0/30%) 0 0) !important;
    transition: ease-out 0.3s;
  }

  /* Confirmation Alert -----------------------------------------------------------------------------------*/
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .confirmation-alert {
    --width: 300px;
    max-width: 90vw;
    position: fixed;
    display: flex;
    align-items: center;
    top: 20px;
    left: 20px;
    background-color: var(--colorText3);
    color: var(--colorText1);
    padding: min(3vw,15px);
    border: 2px solid green;
    background-color: var(--colorText3);
    border-radius: 10px;
    
    z-index: 1000;
    animation: slideInFromLeft 0.5s ease-out;
  }
  .confirmation-alert-icon {
    color: green;
    width: min(5vw,30px);
    height: auto;
  }
  .confirmation-alert-message {
    flex-grow: 1;
    margin: 0 min(2vw,20px);
    font-size: min(3vw,16px);
  }
  .confirmation-alert-button {
    background: none;
    border: none;
    color: green;
    font-size: min(3vw,16px);
    font-weight: bold;
    cursor: pointer;
  }
  /* Floating Feedback Button -----------------------------------------------------------------------------------*/
  .floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #3388FF;
    color: white;
    border: none;
    border-radius: 900px;
    width: min(50vw,200px);
    /* height: min(5vw,50px); */
    aspect-ratio: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 1000;
  }
  .floating-button:hover {
    box-shadow: inset 400px 0 0 0 var(--colorText1);
  }
  .floating-button-icon {
    color: var(--colorText3);
    width: 25px;
    height: auto;
    margin-left: 5px;
  }
  .floating-button-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .floating-button.expanded {
    width: 300px;
    height: auto;
    border-radius: 10px;
    padding: 15px;
  }
  
  .floating-button-expanded-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .floating-button-expanded-content textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    font-size: min(3.5vw,var(--textSizeBody));
    background-color: var(--colorBackground);
    padding: 5px;
    border: 0;
    border-radius: 5px;
  }
  .floating-button-expanded-content button {
    margin-bottom: 5px;
    color:#3388FF;
    font-weight: bold;
    background-color: var(--colorText3);
    border: 0;
    border-radius: 900px;
  }
  .floating-button-expanded-content button:hover {
    color:var(--colorText3);
    background-color: var(--colorTertiary);
  }

  /* Footer -----------------------------------------------------------------------------------*/
  .footer-wrapper {
    background-color: var(--colorPrimary);
    padding: 1.5rem var(--marginPageSide);
    color: var(--colorText3);
  }

  .footer-content {
    max-width: var(--widthContent_MAX);
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;
  }

  .footer-column {
    flex: 1;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .footer-logo {
    max-height: 40px;
    width: auto;
    object-fit: contain;
    margin-bottom: 0.5rem;
    align-self: flex-start;
  }

  .footer-slogan {
    color: var(--colorText3);
    font-size: calc(var(--textSizeBody) * 0.9);
    margin: 0;
  }

  .footer-title {
    color: var(--colorText3);
    font-weight: bold;
    font-size: calc(var(--textSizeTitle) * 0.9);
    margin-bottom: 0.5rem;
  }

  .footer-link {
    color: var(--colorText3);
    text-decoration: none;
    font-size: calc(var(--textSizeBody) * 0.9);
    transition: all 0.3s ease;
    opacity: 0.7;
  }

  .footer-link:hover {
    color: var(--colorTertiary);
    opacity: 1;
  }
  .footer-social-link {
    color: var(--colorText3);
    text-decoration: none;
    font-size: calc(var(--textSizeBody) * 0.9);
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.footer-social-link:hover {
    color: var(--colorTertiary);
}

  @media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        gap: 2rem;
    }
    
    .footer-column {
        width: 100%;
        min-width: unset;
    }
  }
/* Hero Section */
.home-hero-wrapper-container {
    position: relative;
}
.home-hero-wrapper {
  position: relative;
  /* padding-top: calc(var(--marginPageTop) * 3); */
  padding-right: var(--marginPageSide) !important;
  padding-left: var(--marginPageSide) !important;
  /* padding-bottom: calc(var(--marginPageTop) + var(--marginLip)); */
  background-color: var(--colorPrimary);
  border-radius: 0 0 60% 60% / 0 0 var(--marginLip) var(--marginLip);
  overflow: hidden;
}

.home-hero-wrapper-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../soccer_stadium.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  filter: grayscale(100%); /* Make the image grayscale */
  z-index: 1; /* Below the blue overlay */
}

.home-hero-wrapper-color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, var(--colorPrimary), rgba(var(--colorPrimaryRGB), 0.5)); /* Gradient from blue to transparent */
  z-index: 2; /* Above the image */
}
.home-hero-row, .home-hero-col-text, .home-hero-button-container, .home-hero-huge, .home-hero-text, .home-hero-col-image, .home-hero-picture, .home-hero-iphone {
  position: relative;
  z-index: 2; /* Higher z-index to keep content in front of the overlay */
}
.home-hero-row {
  justify-content: space-between;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--widthContent_MAX);
  gap: 50px;
}
@media (max-width: 940px) {
  .home-hero-wrapper {
    padding-top: 0px;
  }
  .home-hero-row {
    /* flex-direction: column-reverse; */
    flex-direction: column;
    max-width: 95%;
    gap: 0px;
  }
  .home-hero-col-text {
    margin: auto;
    margin-top: var(--textSizeHeader);
    text-align: center;
  }
  .home-hero-huge {
    text-align: center;
  }
  .home-hero-container {
    align-content: space-between !important;
  }
  .home-hero-picture{
    max-width: 100%;
  }
  .home-hero-iphone {
    max-width: 100%;
  }
  .home-hero-col-image {
    padding-top: var(--marginSectionVertical);
  }
  .home-hero-button-container {
    margin-top: 0.5rem;
    width: 100%;
    justify-content: center;
  }
  .home-hero-button-join {
    display: block !important;
    width: 100% !important;
    padding: 0.7rem 1.4rem !important;
    font-size: var(--textSizeBody) !important;
  }
  .home-hero-athlete-text {
    width: 100%;
    padding: 0 1rem;
  }
}
.home-hero-col-text {
  max-width: 550px !important;
  align-items: center;
  display: flex;
}
.home-hero-container {
  animation: fadeIn 0.75s ease-out;
}
.home-hero-huge {
  font-size: var(--textSizeHuge);
  line-height: var(--lineSpacingHuge);
  /* color: var(--colorText3); */
  background: linear-gradient(to right, var(--colorTertiary), white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.home-hero-text {
  font-size: var(--textSizeBody);
  color: var(--colorText3);
  padding: var(--paddingHuge) 0px;
  text-align: left;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
}

.home-hero-text-line {
  margin-bottom: 0.5rem;
}

.home-hero-text-line:last-child {
  margin-bottom: 0;
}

.home-hero-break {
  margin-bottom: 0.5rem;
  display: block;
}

.home-hero-col-image {
  align-items: center;
  display: flex;
}
.home-hero-picture {
  max-height: 400px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(100%);
  animation: slideInBottom 1s forwards 0.75s;
}
.home-hero-iphone {
  max-height: 500px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(100%);
  animation: slideInBottom 1s forwards 0.75s;
  border-radius: 0 50px 0 0;
}
@keyframes slideInBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes sweepColor {
  0% {
    background-color: var(--colorTertiary);
  }
  100% {
    background-color: white;
  }
}
@keyframes sweepBorderColor {
  0% {
    box-shadow: inset 0 0 0 0 white;
  }
  100% {
    box-shadow: inset 800px 0 0 0 white;
  }
}

.home-hero-button-container {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
}

.home-hero-button-join {
  background: linear-gradient(120deg, white, var(--colorTertiary)) !important;
  color: var(--colorPrimary) !important;
  border-radius: 30px !important;
  border: 0px !important;
  padding: 0.8rem 2rem !important;
  font-size: calc(var(--textSizeBody) * 1.1) !important;
  margin: 0.25rem 0px;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
}

.home-hero-button-join:hover {
  color: var(--colorPrimary) !important;
  transform: translateY(-2px);
  background: white !important;
}

.home-hero-button-list {
  background-color: transparent !important;
  color: var(--colorTertiary) !important;
  border: 2px solid var(--colorTertiary) !important;
  border-radius: 30px !important;
  padding: 0.6rem 1.1rem !important;
  font-size: var(--textSizeBody) !important;
  font-family: var(--fontPrimary) !important;
  margin: 0.5rem 0px;
  margin-right: 15px;
  box-shadow: inset 0 0 0 0 white;
  -webkit-transition: ease-out 1s;
  -moz-transition: ease-out 1s;
  transition: ease-out 1s;
}
.home-hero-button-list:hover {
  box-shadow: inset 800px 0 0 0 white;
  animation: sweepBorderColor 1s forwards;
  transition: ease-out 1s;
}

.home-hero-athlete-text {
  color: var(--colorText3);
  font-size: var(--textSizeBody);
  margin-top: 1rem;
  text-shadow: 0 0 10px rgba(0, 0, 0, 1) !important;
}

.home-hero-athlete-link {
  color: var(--colorTertiary) !important;
  text-decoration: none;
  transition: opacity 0.3s ease;
  text-shadow: 0 0 10px rgba(0, 0, 0, 1) !important;
}

.home-hero-athlete-link:hover {
  color: white !important;
  text-decoration: underline !important;
}

/* PITCH SECTION */
.home-pitch-background {
  width: 100%;
  background: 
    radial-gradient(circle at 20% 20%, rgba(var(--colorTertiaryRGB), 0.15), transparent 40%),
    radial-gradient(circle at 80% 80%, rgba(var(--colorPrimaryRGB), 0.15), transparent 40%),
    var(--colorSecondary);
  position: relative;
  overflow: hidden;
  margin-top: calc(var(--marginLip) * -1);
  padding-top: var(--marginLip);
}

.home-pitch-background::before {
  content: '';
  position: absolute;
  inset: 0;
  background: 
    linear-gradient(45deg, transparent 48%, rgba(var(--colorTertiaryRGB), 0.2) 50%, transparent 52%) 0 0 / 20px 20px,
    linear-gradient(-45deg, transparent 48%, rgba(var(--colorPrimaryRGB), 0.2) 50%, transparent 52%) 0 0 / 20px 20px;
  opacity: 0.8;
  animation: patternMove 15s linear infinite;
}

@keyframes patternMove {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 40px;
  }
}

.home-pitch-wrapper {
  padding: calc(var(--marginSectionVertical) * 2) var(--marginPageSide);
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  position: relative;
  z-index: 1;
}
.home-pitch-wrapper.slide-in {
  animation: slideInFromBottom 1s forwards;
}
.home-pitch-row {
  justify-content: space-between;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--widthContent_MAX);
  gap: 60px;
}
@media (max-width: 940px) {
  .home-pitch-row {
    flex-direction: column;
    max-width: 95%;
    gap: 40px;
  }
  .home-pitch-title {
    text-align: center;
  }
}
.home-pitch-col-text {
  flex: 1;
  max-width: 600px;
}
.home-pitch-col-visual {
  flex: 1;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-pitch-title {
  font-size: min(var(--textSizeHuge),8vw);
  color: var(--colorPrimary);
  margin-bottom: 30px;
  line-height: 1.2;
}
.home-pitch-subtitles {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.home-pitch-subtitles p {
  font-size: var(--textSizeBody);
  color: var(--colorText1);
  margin: 0;
  line-height: 1.5;
}
.home-pitch-text {
  font-size: var(--textSizeBody);
  padding-bottom: var(--textSizeBodySpacing);
}
/* Add this new style for the title */
.home-pitch-title-centered {
  text-align: center;
  width: 100% !important;
  font-size: var(--textSizeTitle);
  color: var(--colorPrimary);
}

/* Adjust the home-pitch-col-visuals to ensure proper spacing */
.home-pitch-col-visuals {
  max-width: 700px !important;
  display: flex;
  flex-direction: column; /* Stack title and visuals */
  align-items: center; /* Center align the items */
  gap: 20px;
}
.home-pitch-picture {
  width: 100%;
  max-height: 380px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.home-pitch-statistics-container {
  display: flex;
  justify-content: center;
  gap: min(3vw,20px);
}
.home-pitch-visual {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: min(40vw,250px);
}
.home-pitch-visual .inner {
  border-radius: 10px;
  padding: 10px;
  background: var(--colorBackground);
  color: #fff;
  position: relative;
  z-index: 1;
  width: 100%;
  margin: 5px;
}
.home-pitch-visual::before {
  content: "";
  display: block;
  background: linear-gradient(to right, rgba(var(--colorPrimaryRGB),0.5), var(--colorPrimary));
  width: min(40vw,250px);
  height: min(50vw,500px);
  position: absolute;
  animation: rotate 5s ease-out;
  animation-iteration-count: 1;
  z-index: 0;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
.home-pitch-statistic  {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures space is distributed between items */
  position: relative;
  width: min(30%, 200px);
  aspect-ratio: 4/5;
  background-color: rgba(var(--colorPrimaryRGB), 0.2);
  padding: 0 min(2vw, 5px);
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1 ease-out;
}
.home-pitch-statistic.fade-in {
  animation: fadeIn 1s forwards;
}
.home-pitch-icon {
  font-size: min(10vw, 90px);
  color: var(--colorPrimary);
  margin: min(4vw, 30px) 0 20px 0; /* Adjusted margin to ensure 20px space below */
  margin-left: auto;
  margin-right: auto;
}
.home-pitch-huge {
  font-size: var(--textSizeHuge);
  line-height: var(--lineSpacingHuge);
  margin-bottom: min(2vw,var(--paddingHuge));
}
.home-pitch-source {
  color: var(--colorPrimary);
  text-align: center !important;
  font-size: min(2vw, var(--textSizeMini));
  margin-top: auto; /* Ensures the source is at the bottom */
  margin-bottom: 10px; /* Add some margin at the bottom */
  align-self: bottom; /* Align the source to the bottom */
}
.home-pitch-button-container {
  display: flex;
  gap: 10px; /* Adjust the gap between buttons as needed */
  margin-top: 10px; /* Adjust the top margin as needed */
}
.home-pitch-button {
  display: flex;
  align-items: center;
  padding: 10px min(2.5vw,20px) !important;
  background-color: var(--colorPrimary) !important;
  color: white !important;
  border: none !important;
  border-radius: 10px;
  cursor: pointer;
  font-size: min(3.5vw,var(--textSizeBody));
  box-shadow: inset 0 0 0 0 var(--colorTertiary);
  -webkit-transition: ease-out 0.5s;
  -moz-transition: ease-out 0.5s;
  transition: ease-out 0.5s;
}
.home-pitch-button:hover {
  background-color: var(--colorTertiary);
  box-shadow: inset 400px 0 0 0 var(--colorTertiary);
  transition: ease-out 0.5s;
}
.home-pitch-button-icon {
  margin-right: min(2vw,10px); /* Adjust the space between icon and text as needed */
  font-size: 20px; /* Adjust the icon size as needed */
  transition: ease-out 0.5s;
}

/* WHY SECTION-------------------------------------------------------------------------------------------------*/
@media (max-width: 660px) {
  .home-why-header {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
  }
}
.home-why-wrapper {
  padding: var(--marginSectionVertical) var(--marginPageSide);
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  margin-bottom: 1rem;
}
.home-why-wrapper.slide-in {
  animation: slideInFromBottom 1s forwards;
}
.home-why-header {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(var(--textSizeHuge),8vw);
  margin-bottom: 1.5rem;
}
.home-why-subtitle {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem auto;
}
.home-why-row {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  gap: 20px;
}
.home-why-col {
  flex: 0 1 calc(33.333% - 20px); /* 3 columns by default with gap consideration */
  max-width: 370px !important;
  min-width: 280px; /* Prevent columns from getting too narrow */
  padding: 1rem 1rem;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  margin: 0 auto; /* Add this to center the columns */
}

/* Media query for tablet view - 2 columns */
@media (max-width: 940px) {
  .home-why-col {
    flex: 0 1 calc(50% - 20px); /* 2 columns */
    max-width: 370px; /* Keep max-width consistent */
  }
}

/* Media query for mobile view - 1 column */
@media (max-width: 660px) {
  .home-why-col {
    flex: 0 1 370px; /* Fixed width instead of 100% */
    width: 100%;
    max-width: 370px; /* Keep max-width consistent */
  }
}

/* Keyframes for fade-in effect */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Class to apply the animation */
.home-why-col.fade-in {
  animation: fadeIn 0.5s forwards;
}
.home-why-icon{
  height: 120px;
  width: auto;
}
.home-why-icon-color {
  fill: var(--colorPrimary)
}
.home-why-title {
  font-size: var(--textSizeTitle);
  color: var(--colorPrimary);
  padding-top: var(--paddingTitle);
}
.home-why-line {
  color: var(--colorPrimary);
  width: 50%;
  margin: 10px auto 10px auto;
  border: 1px solid;
  border-radius: 100rem;
  opacity: 1.0;
}
.home-why-text {
  font-size: var(--textSizeBody);
}

/* HOW SECTION-------------------------------------------------------------------------------------------------*/
.home-how-wrapper {
  padding: var(--marginSectionVertical) var(--marginPageSide);
  background-color: var(--colorSecondary);
  opacity: 0; /* Ensure the element is hidden initially */
  transform: translateY(100px); /* Start with the element moved down */
  transition: opacity 1s ease-out, transform 1s ease-out;
}
.home-how-wrapper.slide-in {
  animation: slideInFromBottom 1s forwards;
}
.home-how-header {
  text-align: center;
  font-size: min(var(--textSizeHuge),8vw);
  margin-bottom: 1rem;
}
.home-how-subtitle {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 2rem auto;
}
.home-how-row {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: var(--widthContent_MAX);
  justify-content: space-between;
  gap: 40px;
}
@media (max-width: 1220px) {
  .home-how-row {
    flex-direction: column;
    gap: 10px;
  }
  .home-how-col-slideshow {
    margin: auto
  }
  .home-how-timeline::before {
    top: 90px !important;
    bottom: 90px !important;
  }
}
@media (max-width: 600px) {
  .home-how-timeline {
    margin-left:0 !important;
  }
  .home-how-timeline::before {
    top: 90px !important;
    bottom: 90px !important;
  }
}

.home-how-col-slideshow {
  max-width: 480px !important;
  width: 75%;
  align-content: center;
  justify-content: center;
}
.home-how-slideshow-slide {
  position: relative;
  text-align: center;
}
.home-how-slideshow-image {
  height: 100% !important;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}
.home-how-slideshow-image-overlay {
  position: absolute;
  /* background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(var(--colorPrimaryRGB), 0.6)); */
  background: linear-gradient(rgba(var(--colorTertiaryRGB), 0.6), rgba(var(--colorPrimaryRGB), 0.6));
  width: 100% !important;
  height: 100% !important;
  top: 0px;
  right: 0px;
}
.home-how-slideshow-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.carousel-control-prev,
.carousel-control-next {
  top: 90% !important; /* Adjust this value to control how far from the top you want the indicators */
  transform: translateY(-50%);
  width: 10% !important;
}
.home-how-slideshow-quote-icon {
  height: min(10vw,3rem);
  width: auto;
  margin-right: auto;
  display: inline-block;
}
.home-how-slideshow-quote-icon:last-of-type {
  margin-right: 0;
  margin-left: auto;
  transform: scale(-1);
}
.home-how-slideshow-text {
  color: white;
  font-size: min(4vw,var(--textSizeTitle));
  text-align: left;
}

.home-how-timeline {
  position: relative;
  max-width: 550px;
  margin: 0 auto;
  padding: min(1px,1vw) 0;
}
.home-how-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: var(--timeline-left);
  width: 4px;
  /* height:100%; */
  top: 80px;
  bottom: 90px;
  background-color: #999;
}
.home-how-timeline-entry {
  position: relative;
  margin: 20px 0;
  display: flex; /*added*/
}
.home-how-timeline-circle {
  position: absolute;
  /* top: 0; */
  top: 50%;
  transform: translateY(-50%);
  left: var(--timeline-left);
  width: var(--circle-size);
  height: var(--circle-size);
  margin-left: calc(-1 * var(--circle-size) / 2);
  background-color: var(--colorPrimary);
  /* border: solid var(--colorPrimary) 3px; */
  border-radius: 50%;
  color: var(--colorText3);
  text-align: center;
  align-content: center;
  font-weight: bold;
  font-size: min(var(--textSizeTitle),5vw);
}
.home-how-timeline-content {
  background-color: white;
  border-bottom: 5px solid var(--colorPrimary);
  padding: var(--content-padding);
  border-radius: 10px;
  position: relative;
  width: min(600px,75vw);
  left: calc(var(--timeline-left) + min(50px,10vw));
}
.home-how-timeline-content::before {
  content: '';
  position: absolute;
  /* top: 10px; */
  top: 50%;
  transform: translateY(-50%);
  left: calc(var(--content-padding) * -1);
  width: 0;
  height: 0;
  border-width: 10px 20px 10px 0;
  border-style: solid;
  border-color: transparent white transparent transparent;
}
.home-how-timeline-content {
  background-color: white;
  border-bottom: 5px solid var(--colorPrimary);
  padding: var(--content-padding);
  border-radius: 10px;
  position: relative;
  width: min(600px, 75vw);
  left: calc(var(--timeline-left) + min(50px, 10vw));
  opacity: 0;
}
.home-how-timeline-content.fade-in {
  animation: revealFromLeft 1s forwards;
}
@keyframes revealFromLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  50% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.home-how-timeline-icon {
  margin-right: min(3vw,20px);
}
.home-how-timeline-title {
  color: var(--colorPrimary);
  font-weight: bold;
  font-size: min(var(--textSizeTitle),5vw);
  line-height: min(var(--textSizeTitleSpacing),7vw);
}
.home-how-timeline-text {
  color: #A5A5A5;
  font-size: min(var(--textSizeBody),4vw);
}


/* CALL To ACTION SECTION---------------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1100px) {
}

.home-cta-wrapper {
  background: linear-gradient(180deg, rgba(var(--colorTertiaryRGB), 0.9) 0%, var(--colorPrimary) 100%);
  padding: 0px var(--marginPageSide);
  padding-top: var(--marginLip);
  border-radius: 60% 60% 0 0 / var(--marginLip) var(--marginLip) 0 0;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.home-cta-wrapper.slide-in {
  animation: slideInFromBottom 0.5s forwards;
}

.home-cta-container {
  max-width: var(--widthContent_MAX);
  margin: 0 auto;
  padding: var(--marginSectionVertical) 0;
  position: relative;
}

.home-cta-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}

.home-cta-row {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: space-between;
  position: relative;
}

.home-cta-col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.home-cta-title {
  color: white;
  font-size: min(var(--textSizeHuge),8vw);
  text-align: center;
  margin-bottom: 1rem;
}

.home-cta-text {
  color: var(--colorText3);
  font-size: var(--textSizeBody);
  text-align: center;
  margin: 0 0 3rem 0;
  max-width: 400px;
}

.home-cta-button {
  background-color: white !important;
  color: var(--colorPrimary) !important;
  padding: 0.8rem 2.5rem !important;
  font-size: 1.2rem !important;
  border-radius: 30px !important;
  font-weight: bold !important;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border: none !important;
  width: 400px;
  max-width: 400px;
}

.home-cta-button:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  background: white !important;
  color: var(--colorPrimary) !important;
}

@media (max-width: 768px) {
  .home-cta-title {
    font-size: calc(var(--textSizeHuge) * 0.7);
    margin-bottom: 1.5rem;
  }

  .home-cta-text {
    font-size: calc(var(--textSizeBody) * 0.9);
    margin-bottom: 1.5rem;
  }

  .home-cta-button {
    padding: 0.6rem 2rem !important;
    font-size: 1rem !important;
  }
}

@media (max-width: 480px) {
  .home-cta-title {
    margin-bottom: 1rem;
  }

  .home-cta-text {
    font-size: calc(var(--textSizeBody) * 0.85);
    margin-bottom: 1rem;
  }

  .home-cta-button {
    padding: 0.5rem 1.5rem !important;
  }
}

/* Contact form specific styles */
.home-cta-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.home-cta-form-row {
  display: flex;
  gap: 15px;
}

.home-cta-input,
.home-cta-textarea {
  background: transparent;
  border: 1px solid white;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
}

.home-cta-input::placeholder,
.home-cta-textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.home-cta-textarea {
  min-height: 120px;
  resize: vertical;
}

@media (max-width: 768px) {
  .home-cta-form-row {
    flex-direction: column;
  }

  .home-cta-col {
    text-align: center;
    align-items: center;
  }

  .home-cta-section {
    width: 100%;
    align-items: center;
  }

  .home-cta-button {
    width: 100% !important;
  }

  .home-cta-form {
    width: 100%;
  }
}

.topics-container {
  overflow: hidden;
  position: relative;
  margin: 0;
}

.topics-scroll {
  position: relative;
  width: 100%;
}

.topic-item {
  display: flex;
  align-items: center;
  padding: 12px 25px;
  font-size: var(--textSizeBody);
  transition: all 0.3s ease;
  opacity: 0.6;
  transform: scale(0.95);
}

.topic-item.highlighted {
  transform: scale(1.05);
  opacity: 1;
  background-color: rgba(var(--colorPrimaryRGB), 0.1);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(var(--colorPrimaryRGB), 0.1);
}

.topic-icon {
  color: var(--colorPrimary);
  font-size: 1.5em;
  margin-right: 15px;
  min-width: 30px;
  opacity: 0.7;
  transition: all 0.3s ease;
}

.topic-item.highlighted .topic-icon {
  transform: scale(1.1);
  opacity: 1;
  color: var(--colorPrimary);
}

.topic-text {
  color: var(--colorText1);
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.topic-item.highlighted .topic-text {
  color: var(--colorPrimary);
  font-weight: bold;
}

.home-pitch-content-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  margin-top: 30px;
  max-width: var(--widthContent_MAX);
  margin-left: auto;
  margin-right: auto;
}

.home-pitch-content-col {
  flex: 1;
  min-width: 0; /* Prevents flex items from overflowing */
}

.home-pitch-content-col .home-pitch-text {
  text-align: left;
  font-size: var(--textSizeTitle);
  line-height: 1.5;
  padding-top: 20px;
}

@media (max-width: 940px) {
  .home-pitch-content-row {
    flex-direction: column;
    gap: 20px;
  }

  .home-pitch-content-col .home-pitch-text {
    text-align: center;
  }
}

.home-pitch-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.home-pitch-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 25px;
  font-size: var(--textSizeBody);
  line-height: 1.5;
}

.home-pitch-list li::before {
  content: "•";
  color: var(--colorPrimary);
  font-size: 1.5em;
  position: absolute;
  left: 0;
  top: -3px;
}

.home-pitch-list li b {
  color: var(--colorPrimary);
}

@media (max-width: 940px) {
  .home-pitch-list {
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
  }
}

/* Smooth Carousel Styles */
.smooth-carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.smooth-carousel-track {
  display: flex;
  width: 100%;
}

.smooth-carousel-slide {
  flex: 0 0 100%;
  position: relative;
  aspect-ratio: 1;
}

.smooth-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.smooth-carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(var(--colorTertiaryRGB), 0.6), rgba(var(--colorPrimaryRGB), 0.6));
}

.smooth-carousel-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.smooth-carousel-quote-icon {
  height: min(10vw,3rem);
  width: auto;
  margin-right: auto;
}

.smooth-carousel-quote-icon.flip {
  margin-right: 0;
  margin-left: auto;
  transform: scale(-1);
}

.smooth-carousel-text {
  color: white;
  font-size: min(4vw,var(--textSizeTitle));
  text-align: left;
  margin: 1rem 0;
}

@media (max-width: 700px) {
    .home-cta-col {
        text-align: center;
        align-items: center;
    }

    .home-cta-section {
        width: 100%;
        align-items: center;
    }

    .home-cta-button {
        width: 100% !important;
    }

    .home-cta-form {
        width: 100%;
    }
}

@media (max-width: 900px) {
  .home-how-subtitle,
  .home-why-subtitle {
    font-size: min(1.2rem, 4vw);
    margin-bottom: 1.5rem;
  }
}

/* CALL To ACTION SECTION---------------------------------------------------------------------------------------------------------------------*/
.benefits-cta-background {
    width: 100%;
    background: linear-gradient(180deg, rgba(var(--colorTertiaryRGB), 0.9) 0%, var(--colorPrimary) 100%);
    position: relative;
    overflow: hidden;
}

.benefits-section-cta {
    width: 100%;
    background-color: transparent !important;
    max-width: var(--widthContent_MAX);
    margin: 0 auto;
    padding: calc(var(--marginSectionVertical) * 2.5) var(--marginPageSide) calc(var(--marginSectionVertical) * 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.benefits-section-cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: inherit;
}

.benefits-section-cta .benefits-section-title,
.benefits-section-cta .benefits-section-subtitle {
    color: white;
    position: relative;
    z-index: 1;
    text-align: center;
}

.benefits-section-title {
    font-size: min(var(--textSizeHuge),8vw);
    margin-bottom: 1.5rem;
}

.benefits-section-subtitle {
    font-size: 1.2rem;
    max-width: 800px;
    margin-bottom: 2rem;
}

.benefits-cta-button {
    background: white !important;
    color: var(--colorPrimary) !important;
    padding: 0.8rem 2.5rem !important;
    border-radius: 30px !important;
    font-weight: bold !important;
    text-decoration: none;
    display: inline-block;
    transition: all 0.3s ease;
    position: relative;
    z-index: 1;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border: none !important;
}

.benefits-cta-button:hover {
    transform: translateY(-2px) scale(1.02);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Contact Form Section */
.home-cta-wrapper {
    background-color: black;
    padding: 0px var(--marginPageSide);
    padding-top: var(--marginLip);
    border-radius: 60% 60% 0 0 / var(--marginLip) var(--marginLip) 0 0;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.home-cta-wrapper.slide-in {
    animation: slideInFromBottom 0.5s forwards;
}

.home-cta-container {
    max-width: var(--widthContent_MAX);
    margin: 0 auto;
    padding-bottom: var(--marginSectionVertical);
    position: relative;
}

.home-cta-container::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
}

.home-cta-row {
    display: flex;
    gap: 50px;
    justify-content: space-between;
    position: relative;
}

.home-cta-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.home-cta-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.home-cta-text {
    color: var(--colorText3);
    font-size: var(--textSizeBody);
    margin: 0;
}

.home-cta-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.home-cta-form-row {
    display: flex;
    gap: 15px;
}

.home-cta-input,
.home-cta-textarea {
    background: transparent;
    border: 1px solid white;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    width: 100%;
}

.home-cta-input::placeholder,
.home-cta-textarea::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.home-cta-textarea {
    min-height: 120px;
    resize: vertical;
}

@media (max-width: 940px) {
    .home-cta-row {
        flex-direction: column;
        gap: 40px;
    }
    
    .home-cta-row::after {
        display: none;
    }

    .home-cta-col {
        text-align: center;
        align-items: center;
    }

    .home-cta-section {
        width: 100%;
        align-items: center;
    }

    .home-cta-button {
        width: 100% !important;
    }

    .benefits-section-cta {
        padding: calc(var(--marginSectionVertical)) var(--marginPageSide);
    }

    .benefits-section-title {
        margin-bottom: 1rem;
    }

    .benefits-section-subtitle {
        font-size: min(1.2rem, 4vw);
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 768px) {
    .home-cta-form-row {
        flex-direction: column;
    }

    .home-cta-form {
        width: 100%;
    }
}

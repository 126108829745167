.admin-page-format {
  justify-content: center;
  display: flex;
  padding: var(--marginPageTop) var(--marginPageSide);
}
.admin-dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--widthContent_MAX); /* Limit max width */
  min-height: 700px;
  opacity: 0;
  animation: smooth-appear 1s ease forwards;
  background-color: white;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 30px;
  margin: 0 auto 30px auto; /* Center it and add bottom margin */
}
/* Make sure this matches the width of the page content */
@media (max-width: 900px) {
  .admin-page-format {
    padding: 0; /* Set to 0 for smaller screens */
  }
  .admin-dashboard {
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    padding: 20px 20px !important;
    box-shadow: none;
  }
  .admin-dashboard-request-container {
    flex-direction: column;
  }
}
.admin-dashboard-filter {
  padding: 2px;
  border: 1px solid #ccc;
  background-color: white;
  color: var(--colorText1);
  border-radius: 4px;
  margin-left: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-dashboard-filter:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.admin-dashboard-text {
  font-size: min(4vw,var(--textSizeBody));
}
.admin-dashboard-table-container {
  overflow:hidden;
  overflow-y: scroll;
  max-height: min(50vw,200px);
  border: solid 1px var(--colorPrimary);
  margin: 10px 0;
}
.admin-dashboard-table {
  width: 100%;
  border-collapse: collapse;
  font-size: min(3vw,var(--textSizeBody));
}
.admin-dashboard-table-status,
.admin-dashboard-table-status-new,
.admin-dashboard-table-status-approved,
.admin-dashboard-table-status-rejected,
.admin-dashboard-table-status-completed,
.admin-dashboard-table-status-athlete-rejected {
  background-color: orangered;
  border-radius: 100px;
  text-align: center;
  display: inline-block;
  color: var(--colorText3);
  padding: 0 min(2vw,15px);
  font-size: min(3vw, 14px);  /* Adjust this value as needed */
  font-weight: normal;
  white-space: nowrap;
}
.admin-dashboard-table-status-new {
  background-color: #04AA6D;
}
.admin-dashboard-table-status-approved {
  background-color: black;
}
.admin-dashboard-table-status-rejected,
.admin-dashboard-table-status-athlete-rejected {
  background-color: red;
}
.admin-dashboard-table-status-completed {
  background-color: gray;
}
.admin-dashboard-table-header {
  top: 0;
  z-index: 2;
  position: sticky;
  background-color: var(--colorPrimary);
  color:white;
}
.admin-dashboard-request-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.admin-dashboard-request-col-details,
.admin-dashboard-request-col-admin {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border-radius: 10px;
  margin: min(2vw,10px);
  padding: min(3vw,10px);
  flex: 1;
}

.admin-dashboard-request-col-details h3,
.admin-dashboard-request-col-admin h3 {
  margin-bottom: 15px;
  font-size: var(--textSizeHeader);
}

.admin-dashboard-request-col-details p {
  margin-bottom: 10px;
  font-size: min(4vw,var(--textSizeBody));
}

.admin-status-button {
  width: 100%;
  margin-bottom: 15px;
  font-size: min(4vw,var(--textSizeBody)) !important;
  background-color: var(--colorPrimary) !important;
  color: var(--colorText3) !important;
  padding: 0 5px !important;
  border-radius: 10px;
  height: 40px;
  border: 1px solid #ccc;
}

.admin-notes-container {
  margin-top: 15px;
}

.admin-notes-textarea {
  width: 100%;
  min-height: 100px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  font-size: min(4vw,var(--textSizeBody));
}

.admin-update-notes-button {
  width: 100%;
  background-color: var(--colorPrimary);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: min(4vw,var(--textSizeBody));
}

.admin-update-notes-button:hover {
  background-color: rgba(var(--colorPrimaryRGB), 0.8);
}

.admin-dashboard-table-status-awaiting-response {
  background-color: #17a2b8;
  color: #fff;
}

.admin-dashboard-header {
  font-size: var(--textSizeHeader);
  padding-bottom: 5px;
}

.admin-dashboard-request-info {
  font-size: min(4vw,var(--textSizeBody));
}

.admin-button-container {
  margin-top: 15px;
}

.admin-button-approve,
.admin-button-reject {
  width: 100%;
  margin-bottom: 15px;
  font-size: min(4vw,var(--textSizeBody)) !important;
  color: var(--colorText3) !important;
  padding: 0 5px !important;
  border-radius: 10px;
  height: 40px;
  border: 1px solid #ccc;
}

.admin-button-approve {
  background-color: #04AA6D !important;
}

.admin-button-reject {
  background-color: #f44336 !important;
}

.admin-dashboard-video {
  width: 100%;
  margin-bottom: 15px;
  margin-top: min(2vw,15px);
}

.secure-video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-color: black;
  overflow: hidden;
}

.secure-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Ensure video fits properly inside the player */
.plyr video {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

@media (max-width: 900px) {
  .admin-dashboard-request-container {
    flex-direction: column;
  }
  
  .admin-dashboard-request-container{
    width: 100% !important;
  }
}

/* Add this to your existing CSS */

.admin-dashboard-text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}

.admin-dashboard-text label {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 10px;
}

.admin-dashboard-filter {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: white;
  color: var(--colorText1);
  border-radius: 4px;
  margin-left: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: min(3.5vw, var(--textSizeBody));
}

.admin-dashboard-filter:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

@media (max-width: 768px) {
  .admin-dashboard-text {
    flex-direction: column;
    align-items: flex-start;
  }

  .admin-dashboard-text label {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .admin-dashboard-filter {
    width: 100%;
    margin-left: 0;
    margin-top: 5px;
  }
}

.onboard-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.onboard-popup {
    background-color: white;
    border-radius: 12px;
    padding: 32px;
    max-width: 600px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.onboard-content {
    text-align: left;
}

.onboard-content h2 {
    font-size: 24px;
    margin-bottom: 16px;
    color: #333;
    text-align: left;
}

.onboard-subheader {
    color: #000;
    font-weight: bold;
    margin-bottom: 24px;
    text-align: left;
    font-size: 18px;
}

.onboard-subtitle {
    color: #666;
    margin-bottom: 24px;
    text-align: left;
    font-size: 18px;
}

.onboard-steps {
    text-align: left;
    margin-bottom: 32px;
}

.onboard-step {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.step-number {
    background-color: var(--colorPrimary);
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;

    justify-content: center;
    margin-right: 12px;
    flex-shrink: 0;
}

.onboard-step p {
    margin: 0;
    color: #333;
}

.onboard-button {
    background-color: var(--colorPrimary);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.onboard-button:hover {
    background-color: var(--colorTertiary);
}

.onboard-button-container {
    text-align: left;
}

.copied-tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.copy-link {
    position: relative;
    background-color: #f5f5f5;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    text-align: center;
    margin-top: 8px;
}

.copy-link:hover {
    background-color: #e5e5e5;
}

.copy-link.copied {
    background-color: #04AA6D;
    color: white;
}

.step-title {
    font-weight: bold;
    color: #000;
    margin-bottom: 8px;
    font-size: 16px;
}

.step-subtitle {
    color: #666;
    margin-bottom: 16px;
    font-size: 14px;
}

.price-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 16px;
}

.instruction-step {
    margin-bottom: 24px;
    display: flex;
    align-items: flex-start;
}

.instruction-label {
    color: #666;
    font-weight: 500;
    margin-bottom: 4px;
}

.instruction-value {
    color: #635BFF;
    font-weight: bold;
}

.instruction-text {
    margin: 0;
    line-height: 1.5;
    flex: 1;
}

.instruction-section {
    background-color: rgba(4, 170, 109, 0.1);
    padding: 2px 6px;
    border-radius: 4px;
    color: #04AA6D;
    font-weight: 500;
}

.instruction-highlight {
    background-color: rgba(4, 170, 109, 0.1);
    padding: 2px 6px;
    border-radius: 4px;
    color: #04AA6D;
    font-weight: 500;
}

.stripe-connect-button {
    background-color: #635BFF;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-bottom: 32px;
    width: 100%;
}

.stripe-connect-button:hover {
    background-color: #5851E5;
}

.stripe-connect-button:disabled {
    background-color: #A8A6FF;
    cursor: not-allowed;
}

.payment-options {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
}

.payment-option {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
    background-color: white;
}

.payment-option:hover {
    border-color: #04AA6D;
}

.payment-option input[type="radio"] {
    display: none;
}

.payment-option.selected {
    background-color: rgba(4, 170, 109, 0.1);
    border-color: #04AA6D;
}

.payment-option-content {
    flex: 1;
}

.payment-option-title {
    font-weight: 500;
    color: #333;
    font-size: min(3vw, 16px);
}

.manual-payment-info {
    background-color: rgba(4, 170, 109, 0.1);
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 32px;
}

.manual-payment-info a {
    color: #04AA6D;
    text-decoration: none;
    font-weight: 500;
}

.manual-payment-info a:hover {
    text-decoration: underline;
}
